<template>
  <div class="schedule-view" v-if="schedule">
    <h2>{{ schedule.name }}</h2>

    <table style="width: 100%; margin-bottom: 2rem;">
      <tr>
        <th></th>
        <th>
          {{ $t('from') }}
        </th>

        <th>
          {{ $t('to') }}
        </th>
      </tr>

      <tr v-for="day in schedule.days" :key="day.idx">
        <th scope="row">
          {{ $t(day.name) }}
        </th>

        <td><i class="si-clock"></i> {{ day.opens }}</td>

        <td><i class="si-clock"></i> {{ day.closes }}</td>
      </tr>
    </table>

    <div class="actions" v-if="schedule">
      <!-- These actions require the write:site permission to modfy the active opening hours -->
      <div class="primary" v-if="hasAnyPermission(['write:site'])">
        <!-- If this is the currently selected schedule and an override allow the user to remove it-->
        <el-popover
          v-if="isSelected && isOverride"
          placement="top-start"
          width="225"
          :content="revertScheduleMessage"
          trigger="hover"
        >
          <template v-slot:reference>
            <el-button
              type="primary"
              @click="$emit('removeOverride', schedule.id)"
            >
              Remove override
            </el-button>
          </template>
        </el-popover>

        <!-- If this is any other selected schedule allow the user to override it -->
        <el-popover
          v-else-if="isSelected"
          placement="top-start"
          width="225"
          :content="$t('overrideTimes')"
          trigger="hover"
        >
          <template v-slot:reference>
            <el-button type="primary" @click="$emit('override', schedule.id)">
              {{ $t('overrideSchedule') }}
            </el-button>
          </template>
        </el-popover>

        <!-- If this is any other selected schedule allow the user to select it -->
        <el-popover
          v-else
          placement="top-start"
          width="225"
          :content="$t('setOpeningHours', { scheduleName: this.schedule.name })"
          trigger="hover"
        >
          <template v-slot:reference>
            <el-button type="primary" @click="$emit('select', schedule.id)">
              {{ $t('useSchedule') }}
            </el-button>
          </template>
        </el-popover>
      </div>

      <div class="secondary">
        <el-popover
          class="button-tooltip"
          placement="top-start"
          :content="$t('editThisSchedule')"
          trigger="hover"
        >
          <template v-slot:reference>
            <el-button @click="$emit('edit', schedule.id)">
              {{ $t('edit') }}
            </el-button>
          </template>
        </el-popover>

        <el-popover
          placement="top-start"
          class="button-tooltip"
          :content="$t('deleteThisSchedule')"
          trigger="hover"
        >
          <template v-slot:reference>
            <el-button
              type="danger"
              @click="$emit('delete', schedule.id)"
              v-if="!isOverride"
            >
              {{ $t('delete') }}
            </el-button>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Popover } from 'element-ui'
import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleView',
  components: {
    'el-popover': Popover,
    'el-button': Button,
  },
  computed: {
    revertScheduleMessage() {
      // Returns the popover message for the 'remove override' button
      if (
        !this.schedule ||
        !this.schedule.override ||
        !this.schedule.override.name
      ) {
        return this.$t('removeScheduleOverride')
      } else {
        return this.$t('revertToschedule', {
          scheduleName: this.schedule.override.name,
        })
      }
    },
    isOverride() {
      // Is this an override schedule?
      return this.schedule && this.schedule.override
    },
    ...mapGetters(['hasAnyPermission']),
  },
  props: {
    schedule: Object,
    isSelected: Boolean, // Is this the currently selected schedule
  },
}
</script>

<style lang="less" scoped>
table {
  border-collapse: collapse;
}

th {
  font-weight: bold;
}

th,
td {
  padding: 1rem 0.25rem;
}

tr {
  border-bottom: 1px solid #ebeef5;
}

.actions {
  display: flex;

  .primary {
    flex: 1 0 auto;
  }

  .secondary {
    flex: 0 0 auto;
  }
}
@media only screen and (max-width: 780px) {
  .actions {
    display: flex;
    flex-direction: column !important;
    gap: 1vh;
  }
}
</style>
