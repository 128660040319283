<template>
  <bt-modal :visible="visible" @close="$emit('close')" :title="title">
    <!-- modal body -->

    <template v-slot:body>
      <!-- Form -->
      <el-form
        ref="form"
        :model="formData"
        label-width="120px"
        :rules="rules"
        label-position="top"
      >
        <!-- name -->
        <el-form-item :label="$t('tokenName')" prop="name">
          <el-input
            :placeholder="$t('tokenName')"
            v-model="formData.name"
            ref="name"
          />
        </el-form-item>

        <!-- Select for Sites -->
        <el-form-item :label="$t('sites')" prop="sites">
          <site-multiselect v-model="formData.sites" />
        </el-form-item>
      </el-form>

      <!-- Permissions table -->
      <table class="permission-table">
        <!-- Heading row -->
        <tr>
          <th></th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Delete</th>
        </tr>

        <tr v-for="(group, idx) in availablePermissions" :key="idx">
          <th scope="row">{{ $t(group.label) }}</th>
          <!-- Get the translation key from the availablePermissions array below -->
          <td>
            <el-switch
              v-if="group.read"
              :value="permissionsObj['read' + ':' + group.type]"
              @change="togglePermission('read' + ':' + group.type)"
            />
          </td>
          <td>
            <el-switch
              v-if="group.write"
              :value="permissionsObj['write' + ':' + group.type]"
              @change="togglePermission('write' + ':' + group.type)"
            />
          </td>
          <td>
            <el-switch
              v-if="group.delete"
              :value="permissionsObj['delete' + ':' + group.type]"
              @change="togglePermission('delete' + ':' + group.type)"
            />
          </td>
        </tr>
      </table>
    </template>

    <!-- modal footer -->
    <template v-slot:footer>
      <el-button type="success" @click="handleSave">{{ $t('save') }}</el-button>
      <el-button @click="$emit('close')">{{ $t('close') }}</el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from './BtModal'
import SiteMultiSelect from './SiteMultiSelect'
import { Button, Input, Switch, Form, FormItem, Message } from 'element-ui'

export default {
  name: 'TokenModal',
  components: {
    'bt-modal': BtModal,
    'el-button': Button,
    'el-input': Input,
    'el-switch': Switch,
    'el-form': Form,
    'el-form-item': FormItem,
    'site-multiselect': SiteMultiSelect,
  },
  computed: {
    permissionsObj() {
      // Convert the permissions array into an object.
      // [ 'read:ride' ] => { 'read:ride': true }
      if (!this.formData || !this.formData.permissions) return {}

      var result = {}

      this.formData.permissions.forEach((permission) => {
        result[permission] = true
      })

      return result
    },
  },
  data() {
    return {
      title: this.$t('addNewToken'),
      // The formData property will store all the data we need to create the new
      // token in one place. This makes it easier to handle tasks such as
      // as emitting the data in an event or sending the data to the api.
      // Note that the child property names (name, permissions, sites) match
      // those required by the API
      formData: {
        name: null,
        permissions: [],
        sites: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pleaseEnterATokenName'),
            trigger: 'blur',
          },
        ],
        sites: [
          {
            required: true,
            message: this.$t('pleaseSelectSiteS'),
            trigger: 'change',
          },
        ],
      },
      availablePermissions: [
        {
          label: 'rides', // This is a key in the locale files (e.g. en-gb.json)
          type: 'ride', // the second part in the 'read:ride', 'write:ride' etc. strings
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'rideStatuses',
          type: 'ride_status',
          read: true,
          write: true,
        },
        {
          label: 'shows',
          type: 'show',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'showStatuses',
          type: 'show_status',
          read: true,
          write: true,
        },
        {
          label: 'showTypes',
          type: 'show_type',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'showVenues',
          type: 'show_venue',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'fAndB',
          type: 'fandb',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'fAndBStatuses',
          type: 'fandb_status',
          read: true,
          write: true,
        },
        {
          label: 'statusMessages',
          type: 'message',
          write: true,
          delete: true
        },
        {
          label: 'openingHours',
          type: 'schedule',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'birthdays',
          type: 'birthday',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'userTypes',
          type: 'user_type',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'tokens',
          type: 'token',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'sites',
          type: 'site',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'logEntries',
          type: 'log_entry',
          read: true
        }
      ],
    }
  },
  methods: {
    togglePermission(permission) {
      // This function adds the value of the permission property to
      // this.formData.permissions, or takes it away if it is alread there
      if (!permission) return

      // Find the position of the permission in the array
      var idx = this.formData.permissions.indexOf(permission)

      if (idx === -1) {
        // This permission isn't currently listed in the permissions array. Add it.
        this.formData.permissions.push(permission)
      } else {
        // This permission is listed . Take it out of the array
        this.formData.permissions.splice(idx, 1)
      }
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        //do some more checks
        var hasName = this.formData.name
        var hasSites = this.formData.sites.length != 0
        var hasPermissions = this.formData.permissions.length != 0
        if (valid && hasName && hasSites && hasPermissions) {
          this.$emit('save', this.formData)
          this.formData = {
            name: null,
            permissions: [],
            sites: [],
          }
        } else {
          hasName ? true : Message.error('Name is required.')
          hasSites ? true : Message.error('Please select sites.')
          hasPermissions ? true : Message.error('Please select permissions.')
          if (hasName && hasSites && hasPermissions) {
            Message.error('Unknown error')
            this.resetForm()
          }
          return
        }
      })
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.$nextTick(() => {
          if (this.$refs.name) {
            this.$refs.name.focus()
          }
        })
      } else {
        this.formData = {
          name: null,
          permissions: [],
          sites: [],
        }
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
