<template>
  <bt-modal :visible="visible" @close=close :title="$t('setAllShows')">
    <template v-slot:body>
      <el-form label-position="top">
        <div style="display: flex; align-items: center">
          <el-form-item label="Status message" size="medium" style="margin-right: 1rem;">
            <el-select
              v-if="messages && messages.length"
              v-model="formData.statusMessage"
            >
              <el-option
                v-for="message in messages"
                :key="message.id"
                :label="message.value"
                :value="message.value">
              </el-option>
            </el-select>

            <el-input v-model="formData.statusMessage" v-else></el-input>
          </el-form-item>

          <div class="switches">
            <el-form-item label="Open / closed" class="center">
              <el-switch v-model="formData.operational"/>
            </el-form-item>
          </div>
        </div>

        <ol class="time-list">
          <li v-for="(time, idx) in formData.times" :key="idx">
            <el-time-picker
              :value="timeObjs[idx]"
              @input="setTime($event, idx)"
              format="HH:mm"
              :clearable="false"
              class="show-time-picker"
            >
            </el-time-picker>
            <el-button type="danger" icon="si-trash" @click="deleteTime(idx)">
            </el-button>
          </li>
        </ol>

        <el-button @click="addTime">{{ $t('addTime') }}</el-button>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">{{ $t('setAllShows') }}</el-button>
      <el-button @click="close">{{ $t('close') }}</el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal';
import { Form, FormItem, Input, Button, Switch, TimePicker, Option, Select } from 'element-ui';
import { DateTime } from 'luxon';

export default {
  name: 'SetAllShowsModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-button': Button,
    'el-switch': Switch,
    'el-time-picker': TimePicker,
    'el-option': Option,
    'el-select': Select
  },
  computed: {
    timeObjs() {
      if (!this.formData || !this.formData.times) return [];

      return this.formData.times.map(time => {
        var [hours, minutes] = time.split(':');

        return DateTime.fromObject({
          hour: hours,
          minute: minutes,
          second: 0,
          millisecond: 0
        }).toJSDate();
      });
    }
  },
  data() {
    return {
      detectChanges: false, // Detect unsaved changes?
      unsavedChanges: false, // Are there unsaved changes?
      formData: {
        operational: false,
        statusMessage: undefined,
        times: []
      }
    }
  },
  methods: {
    deleteTime(idx) {
      // Copy the times array
      var newArr = this.formData.times.slice(0);
      // Remove the deleted time
      newArr.splice(idx, 1);
      this.formData.times = newArr;
      //this.set('times', newArr);
    },
    addTime() {
      // Add a show time
      // Copy the times array
      var newArr = this.formData.times ? this.formData.times.slice(0) : [];

      // Add a new time to the copied array;
      newArr.push('12:00');
      this.formData.times = newArr;
      //this.set('times', newArr);
    },
    setTime(time, position) {
      // When the user updates a time update the whole array
      // First create a time string for the new time
      var newTime = DateTime.fromJSDate(time).toFormat('HH:mm');
      // Copy this.value.times. Replace the edited entry with the new string
      var newArr = this.formData.times.map((time, idx) => idx === position ? newTime : time);
      this.formData.times = newArr;
    },
    save() {
      // Clone the data before manipulating it
      var data = JSON.parse(JSON.stringify(this.formData));

      // Emit a save event back to the parent
      if (!data.useCustomOpeningTimes) {
        // If useCustomOpeningTimes is disabled we shouldn't overwrite any
        // existing opening time
        delete data.customOpeningTimes;
      }

      this.$emit('save', data);
    },
    close() {
      this.$emit('close');
    },
    toDateObject(time) {
      // Convert a time string (hh:mm) to a Date object
      var [hours, mins] = time.split(':');
      var date = new Date();
      date.setHours(hours);
      date.setMinutes(mins);
      return date;
    },
    toTimeString(date) {
      // Convert a Date object to a time string (hh:mm)
      var hours = date.getHours().toString();
      var mins = date.getMinutes().toString();
      if (hours.length === 1) hours = '0' + hours;
      if (mins.length === 1) mins = '0' + mins;
      return hours + ':' + mins;
    }
  },
  props: {
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    messages: Array
  },
  watch: {
    visible() {
      // Reset the data if the visible status changes
      this.detectChanges = false;

      this.formData = {
        operational: false,
        statusMessage: undefined,
        times: []
      };
    },
    formData: {
      // Detect unsaved changes
      handler() {
        if (this.detectChanges) {
          this.unsavedChanges = true;
        } else {
          this.detectChanges = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.switches {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.time-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.time-list li {
  display: flex;
  margin-bottom: 0.5rem;

  & > .show-time-picker {
    margin-right: 0.5rem;
  }
}


</style>