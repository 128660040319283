var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('bt-menu',{attrs:{"label":_vm.$t('mainMenu'),"menuVisible":_vm.menuVisible},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":require("../assets/img/merlin-logo-white.png"),"width":"108","height":"85"}})])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[_c('a',{staticClass:"sn",attrs:{"href":"#","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/img/sn-button.svg"),"alt":"signage.ninja"}})]),_c('a',{staticClass:"bt",attrs:{"href":"https://beavertrison.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/img/bt-logo.svg"),"alt":"Beaver Trison"}})])])]},proxy:true}])},[_c('bt-menu-item',{attrs:{"to":{ name: 'dashboard', params: { siteId: _vm.selectedSiteId } }}},[_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#dashboard"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('dashboard')))])]),(
      _vm.hasAnyPermission(['read:ride', 'write:ride', 'delete:ride']) &&
      _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.rides
    )?_c('bt-menu-item',{attrs:{"to":{ name: 'rides', params: { siteId: _vm.selectedSiteId } }}},[_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#ride"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('rides')))])]):_vm._e(),(
      _vm.hasAnyPermission(['read:show', 'write:show', 'delete:show']) &&
      _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.shows
    )?_c('bt-menu-item',{attrs:{"to":{ name: 'shows', params: { siteId: _vm.selectedSiteId } }}},[_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#show"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('shows')))])]):_vm._e(),(
      _vm.hasAnyPermission(['read:fandb', 'write:fandb', 'delete:fandb']) &&
      _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.foodVendors
    )?_c('bt-menu-item',{attrs:{"to":{ name: 'food vendors', params: { siteId: _vm.selectedSiteId } }}},[_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#food"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('foodAndDrink')))])]):_vm._e(),(_vm.hasAnyPermission(['read:schedule', 'write:schedule', 'delete:schedule']))?_c('bt-menu-item',{attrs:{"to":{ name: 'opening schedules', params: { siteId: _vm.selectedSiteId } }}},[_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#clock"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('openingHours')))])]):_vm._e(),(
      _vm.hasAnyPermission(['read:birthday', 'write:birthday', 'delete:birthday']) &&
      _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.birthdays
    )?_c('bt-menu-item',{attrs:{"to":{ name: 'birthdays', params: { siteId: _vm.selectedSiteId } }}},[_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#cake"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('birthdays')))])]):_vm._e(),(_vm.hasAnyPermission(['admin']))?_c('bt-sub-menu',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#gear"}})]),_c('span',[_vm._v(_vm._s(_vm.$t('admin')))])]},proxy:true}],null,false,1898321050)},[_c('bt-menu-item',{attrs:{"to":{ name: 'user types', params: { siteId: _vm.selectedSiteId } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('userTypes')))])]),_c('bt-menu-item',{attrs:{"to":{ name: 'tokens', params: { siteId: _vm.selectedSiteId } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('authenticationTokens')))])]),_c('bt-menu-item',{attrs:{"to":{ name: 'sites', params: { siteId: _vm.selectedSiteId } }}},[_c('span',[_vm._v(_vm._s(_vm.$t('sites')))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }