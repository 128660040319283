<template>
  <div class="birthday-form">
    <h2>{{ $d(selectedDateObj, 'mediumWithYear') }}</h2>
    <p v-if="!formData || !formData.length" class="empty">
      {{ $t('noBirthdaysOnThisDay') }}
    </p>

    <el-form ref="form" :model="{ data: formData }" label-position="top" size="small">
      <div class="form-row" v-for="(birthday, idx) in formData" :key="idx">
        <el-form-item
          :label="$t('givenName')"
          :prop="'data.' + idx + '.firstName'"
          :rules="[
            { required: true, message: $t('pleaseEnterAName'), trigger: 'blur' },
          ]"
        >
          <el-input v-model="birthday.firstName" />
        </el-form-item>

        <el-form-item :label="$t('familyName')" :prop="'data.' + idx + '.lastName'">
          <el-input v-model="birthday.lastName" />
        </el-form-item>

        <el-form-item :label="$t('age')" :prop="'data.' + idx + '.age'">
          <el-input-number v-model="birthday.age" :min="0" controls-position="right" />
        </el-form-item>

        <el-form-item>
          <el-button type="danger" @click="deleteName(idx)">
            {{ $t('delete') }}
          </el-button>
        </el-form-item>
      </div>

      <el-form-item>
        <el-button type="primary" @click="saveNames" :disabled="!unsavedChanges">{{ $t('save') }}</el-button>
        <el-button @click="addName">{{ $t('addName') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Input, InputNumber, Button, Form, FormItem } from 'element-ui';

export default {
  name: 'BirthdayForm',
  components: {
    'el-input': Input,
    'el-input-number': InputNumber,
    'el-button': Button,
    'el-form': Form,
    'el-form-item': FormItem
  },
  data() {
    return {
      formData: null,
      detectChanges: false, // Should we detect changes to the formData
      toDelete: [] // This will store a list of names to be deleted when the user saves
    }
  },
  methods: {
    setDayState() {
      // When selecting a day (or the data first loads) use this to setup the
      // state for that day
      if (!this.birthdays) this.formData = [];

      this.detectChanges = false;

      this.formData = this.birthdays.map(birthday => {
        return {
          id: birthday.id, // Make sure we keep the ID to handle updates and deletes
          firstName: birthday.firstName,
          lastName: birthday.lastName,
          age: birthday.age,
          birthDate: birthday.birthDate
        }
      });

      this.$emit('modified', false);
      this.toDelete = [];
    },
    addName() {
      // Add a name to the bottom of the form
      this.formData.push({
        firstName: null,
        lastName: null,
        age: null,
        birthDate: this.selectedDate
      });

      // Emit an event to notify the parent component that unsaved changes exist
      this.$emit('modified', true);
    },
    deleteName(idx) {
      // Remove the deleted birthday from the array.
      var [deletedBirthday] = this.formData.splice(idx, 1);

      if (deletedBirthday.id) {
        // An ID field indicates that this birthday has previously been saved,
        // and will need to be deleted from the database. This will be handled
        // whe the user clicks save, but for now add it to the toDelete array
        this.toDelete.push(deletedBirthday)
      }
      // If there's no ID then we can just delete the birthday from local state
    },
    saveNames() {
      // Send an event to the parent indicating that we need to save
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('save', this.formData, this.toDelete)
        }
      });
    }
  },
  props: {
    birthdays: Array,
    unsavedChanges: Boolean, // Are there unsaved changes for the current day?
    selectedDate: String, // The current date selected in the calendar
    selectedDateObj: Date // As abovem but as a Date object
  },
  watch: {
    formData: {
        deep: true,
        handler: function() {
          // If the formData changes and detectChanges is true then set unsaved
          // changes to true
          if (!this.detectChanges) {
            this.detectChanges = true;
            return;
          }
          // Emit an event to notify the parent component that unsaved changes exist
          this.$emit('modified', true);
        }
    },
    birthdays: function() {
      this.setDayState();
    },
    selectedDate: {
      handler: function() {
        this.setDayState();
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.form-row {
  display: grid;
  grid-template-columns: minmax(6rem, 9rem) minmax(6rem, 9rem) minmax(5rem, 6rem) minmax(auto, 6rem);
  place-items: end start;
  gap: 1rem;
}

.el-input-number {
  width: auto;
}

.el-input {
  width: auto !important;
}

.empty {
  font-style: italic;
}

@media only screen and (max-width: 520px) {
  .form-row {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
</style>