<template>
  <div class="site-select">
    <el-select v-if="availableSites" :value="selectedSiteId" @input="loadSite">
      <el-option v-for="site in availableSites" :key="site.id" :value="site.id" :label="site.name">

         {{ site.name }}

      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Select, Option } from 'element-ui';

export default {
  name: 'SiteSelect',
  components: {
    'el-select': Select,
    'el-option': Option
  },
  computed: {
    ...mapState([
      'selectedSiteId'
    ]),
    ...mapGetters([
      'availableSites'
    ])
  },
  methods: {
    ...mapActions([
      'loadSite'
    ])
  }
}
</script>
<style lang="less" scoped>
@import '../styles/vars';

.el-select /deep/ .el-input__inner{
  background-color: @grey12;
  border:0.2px dotted @grey11;
  font-family: 'Nunito';
  font-size:1.2rem;
  font-weight:600;
}

.el-select /deep/ .el-input .el-select__caret{
  font-size: 1.25rem;
  font-weight: 800;
}

@media only screen and (max-width: 700px) {
  .el-select /deep/ .el-input__inner{
    font-size: 1rem;
  }

  .el-select /deep/ .el-input .el-select__caret{
    font-size: 1rem;
  }
}
</style>