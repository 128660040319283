<template>
  <div class="site dashboard view">
    <h1 class="page-heading">{{ $t('dashboard') }}</h1>

    <main class="dashboard">
      <div class="status">
        <template v-if="loaded">
          <!-- *** RIDES *** -->
          <div
            class="panel rides"
            v-if="
              hasAnyPermission(['read:ride', 'write:ride', 'delete:ride']) &&
              currentSite && currentSite.siteFeatures && currentSite.siteFeatures.rides
            "
          >
            <div class="flex space-between">
              <h2>{{ $t('rides') }}</h2>
              <!-- <img class="icon" src="../assets/svg-icons/ride.svg"> -->
              <svg class="icon">
                <use xlink:href="#ride"></use>
              </svg>
            </div>
            <div class="flex space-between">
              <!-- open/close -->
              <div style="flex: 1 0 50%;">
                <h3>
                  <span class="circle teal">{{ rideInfo.open }}</span>
                  {{ $t('open') }}
                </h3>
                <h3>
                  <span class="circle rose">{{ rideInfo.closed }}</span>
                  {{ $t('closed') }}
                </h3>
              </div>
              <!-- percentage -->
              <div class="progress">
                <div class="barOverflow">
                  <div
                    class="bar"
                    :style="{ transform: 'rotate(' + getOpenDeg + 'deg)' }"
                  ></div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <el-button round class="expand">
                <router-link
                  :to="{ name: 'rides', params: { siteId: this.selectedSiteId } }"
                >
                  {{ $t('details') }} <i class="si-arrow-right"></i>
                </router-link>
              </el-button>
            </div>
          </div>

          <!-- ***  SHOWS ***  -->
          <div
            class="panel show"
            v-if="
              hasAnyPermission(['read:show', 'write:show', 'delete:show']) &&
              currentSite && currentSite.siteFeatures && currentSite.siteFeatures.shows
            "
          >
            <div class="flex space-between">
              <h2>{{ $t('shows') }}</h2>
              <svg class="icon">
                <use xlink:href="#show"></use>
              </svg>
            </div>
            <div class="flex space-between">
              <!-- active/inactive -->
              <div>
                <h3>
                  <span class="circle white">{{ showInfo.active }}</span>
                  {{ $t('active') }}
                </h3>
                <h3>
                  <span class="circle black">{{ showInfo.inactive }}</span>
                  {{ $t('inactive') }}
                </h3>
              </div>
              <!-- graphs -->
              <div class="show-graphs">
                <div
                  class="active"
                  :style="{ height: activeShows + '%' }"
                ></div>
                <div
                  class="inactive"
                  :style="{ height: 100 - activeShows + '%' }"
                ></div>
              </div>
            </div>
            <div class="panel-footer">
              <el-button round class="expand">
                <router-link
                  :to="{ name: 'shows', params: { siteId: this.selectedSiteId } }"
                >
                  {{ $t('details') }} <i class="si-arrow-right"></i>
                </router-link>
              </el-button>
            </div>
          </div>

          <!-- ***  BIRTHDAYS ***  -->
          <div
            class="panel birthday"
            v-if="
              hasAnyPermission([
                'read:birthday',
                'write:birthday',
                'delete:birthday',
              ]) &&
              currentSite && currentSite.siteFeatures && currentSite.siteFeatures.birthdays
            "
          >
            <div class="flex space-between">
              <h2>{{ $t('birthdays') }}</h2>
              <svg class="icon">
                <use xlink:href="#cake"></use>
              </svg>
            </div>
            <!-- number of birthdays today -->
            <div class="padding-left padding-right birthdays-wrapper">
              <div class="birthdays">
                <h2>{{ birthdays.length }}</h2>
              </div>
              <div class="birthday-text">
                <h3>{{ $tc('birthdaysToday', birthdays.length) }}</h3>
              </div>
            </div>

            <div class="panel-footer">
              <el-button round class="expand">
                <router-link
                  :to="{
                    name: 'birthdays',
                    params: { siteId: this.selectedSiteId },
                  }"
                >
                  {{ $t('details') }} <i class="si-arrow-right"></i>
                </router-link>
              </el-button>
            </div>
          </div>

          <!-- ***  NINJA LOGO ***  -->
          <div class="panel signage">
            <div>
              <a
                href="https://my.signage.ninja/"
                target="_blank"
                ><img src="../assets/img/signage-logo.svg"
              /></a>
              <!--<a
                href="https://dev.signage.ninja/#/login?saml=merlin"
                target="_blank"
                ><img src="../assets/img/signage-logo.svg"
              /></a>-->
            </div>
          </div>

          <!-- ***  ADMIN ***  -->
          <div class="panel admin" v-if="hasAnyPermission(['admin'])">
            <div class="flex space-between">
              <h2>{{ $t('admin') }}</h2>
              <svg class="icon">
                <use xlink:href="#gear"></use>
              </svg>
            </div>
            <div class="admin-links-container">
              <el-button
                size="mini"
                type="info"
                plain
                shadow="hover"
                class="admin-links"
              >
                <router-link
                  :to="{
                    name: 'users',
                    params: { siteId: this.selectedSiteId },
                  }"
                  class="admin-links"
                >
                  Users
                </router-link>
              </el-button>
              <el-button
                size="mini"
                type="info"
                plain
                shadow="hover"
                class="admin-links"
              >
                <router-link
                  :to="{
                    name: 'user types',
                    params: { siteId: this.selectedSiteId },
                  }"
                  class="admin-links"
                >
                  User Types
                </router-link>
              </el-button>
              <el-button
                size="mini"
                plain
                type="info"
                shadow="hover"
                class="admin-links"
              >
                <router-link
                  :to="{
                    name: 'tokens',
                    params: { siteId: this.selectedSiteId },
                  }"
                  class="admin-links"
                >
                  Tokens
                </router-link>
              </el-button>
              <el-button
                size="mini"
                type="info"
                plain
                shadow="hover"
                class="admin-links"
              >
                <router-link
                  :to="{
                    name: 'sites',
                    params: { siteId: this.selectedSiteId },
                  }"
                  class="admin-links"
                >
                  Sites
                </router-link>
              </el-button>
            </div>
          </div>

          <!-- ***  OPENING HOURS ***  -->
          <div
            class="panel schedule"
            v-if="
              hasAnyPermission([
                'read:schedule',
                'write:schedule',
                'delete:schedule',
              ])
            "
          >
            <div class="flex space-between">
              <h2>{{ $t('openingHours') }}</h2>
              <svg class="icon">
                <use xlink:href="#clock"></use>
              </svg>
            </div>
            <!-- Check if a schedule has been assigned before trying to display it -->
            <template v-if="currentSite && currentSite.schedule">
              <div class="schedule-text">
                <h2 class="schedule-text-bg">
                  {{ currentSite.schedule.name }}
                </h2>
                <h2 style="margin-block-start: 0 !important;">
                  Active now
                </h2>
              </div>
            </template>
            <div class="panel-footer">
              <el-button round class="expand">
                <router-link
                  :to="{ name: 'opening schedules', siteId: this.selectedSiteId }"
                >
                  {{ $t('details') }} <i class="si-arrow-right"></i>
                </router-link>
              </el-button>
            </div>
          </div>
        </template>
      </div>

      <!-- ***  ACTIVITY LOG ***  -->
      <div class="panel flex column" v-if="hasAnyPermission(['read:log_entry'])">
        <h2>{{ $t('activity30Days') }}</h2>
        <div class="log">
          <div class="log-entry-wrapper">
            <log-entry
              v-for="(entry, idx) in logEntries" :key="idx" :entry="entry"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from '../api'
import { mapState, mapGetters } from 'vuex'
import { Button } from 'element-ui'
import { DateTime } from 'luxon';
import Loading from '../mixins/Loading';
import LogEntry from '../components/dashboard/LogEntry';

export default {
  name: 'Dashboard',
  components: {
    'el-button': Button,
    'log-entry': LogEntry
  },
  created() {
    // Determine what data we need to load
    var requests = [];

    if (this.hasAnyPermission([['read:ride', 'write:ride', 'delete:ride']])) {
      requests.push(this.getRides());
    }

    if (this.hasAnyPermission(['read:show', 'write:show', 'delete:show'])) {
      requests.push(this.getShows());
    }

    if (this.hasAnyPermission(['read:birthday', 'write:birthday', 'delete:birthday'])) {
      requests.push(this.getBirthdays());
    }

    if (this.hasAnyPermission(['read:log_entry'])) {
      requests.push(this.getLogs());
    }

    //hasAnyPermission(['read:schedule', 'write:schedule', 'delete:schedule'])

    this.startLoading();
    return Promise.all(requests).then(() => {
      this.loaded = true;
      this.getOpenRides();
      this.getActiveShows();
    }).catch(() => {

    }).finally(() => {
      this.stopLoading();
    })
  },
  computed: {
    ...mapState(['selectedSiteId']),
    ...mapGetters(['currentSite', 'hasAnyPermission']),
    getOpenDeg() {
      var total = this.rideInfo.open + this.rideInfo.closed
      var percentage = (this.rideInfo.open / total) * 100
      var rotate = 45

      if (percentage) {
        rotate += percentage * 1.8
      }
      return rotate
    },
    activeShows() {
      var total = this.showInfo.active + this.showInfo.inactive
      return (this.showInfo.active / total) * 100
    },
  },
  data() {
    return {
      loaded: false,
      rides: [],
      rideInfo: {
        open: 0,
        closed: 0,
      },
      birthdays: null,
      shows: null,
      showInfo: {
        active: 0,
        inactive: 0,
      },
      logEntries: null,
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    }
  },
  methods: {
    getLogs() {
      return api
        .getLogEntries({
          siteId: this.selectedSiteId,
        })
        .then((response) => {
          this.logEntries = response.data
        })
    },
    getRides() {
      return api
        .getRides(this.selectedSiteId, { include: ['status'], active: true })
        .then((response) => {
          this.rides = response.data
        })
    },
    getBirthdays() {
      // Set the startDate and endDate to today
      var timeZone = this.currentSite.timeZone;
      var today = DateTime.now().setZone(timeZone).toFormat('yyyy-MM-dd');

      return api.getBirthdays(this.selectedSiteId, {
        date: today
      }).then((response) => {
        this.birthdays = response.data
      })
    },
    getShows() {
      return api
        .getShows(this.selectedSiteId, { include: ['status'] })
        .then((response) => {
          this.shows = response.data
        })
    },
    getOpenRides() {
      if (!this.rides || !this.rides.length) return;

      for (var i = 0; i < this.rides.length; i++) {
        if (this.rides[i].status.open) {
          this.rideInfo.open++
        } else {
          this.rideInfo.closed++
        }
      }
    },
    getActiveShows() {
      if (!this.shows || !this.shows.length) return;

      for (var i = 0; i < this.shows.length; i++) {
        if (this.shows[i].active) {
          this.showInfo.active++
        } else {
          this.showInfo.inactive++
        }
      }
    },
  },
  mixins: [
    Loading
  ]
}
</script>

<style lang="less" scoped>
@import '../styles/vars';
main.dashboard {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2em;
  grid-template-areas: 'status log';
}

.status {
  grid-area: status;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-gap: 2rem;
  margin-right: 2rem;
}

.log {
  border-top: 1px solid @grey2;
  grid-area: log;
  overflow-y: auto;
  flex-grow: 1;
  height: 8em;
  padding: 1vw;
}

.log-entry-wrapper {
  overflow-y: visible;
}

.circle {
  color: white;
  border-radius: 2rem;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 0.8em;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.teal {
    background: @primary;
  }
  &.rose {
    background: @rose;
  }
  &.white {
    background: white;
    color: @menu-background;
  }
  &.black {
    background: @menu-background;
  }
}

.progress {
  position: relative;
  flex: 0 1 12rem;
}
.barOverflow {
  position: relative;
  overflow: hidden;
  padding-top: 50%;
}
.bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2em solid @rose;
  border-bottom-color: @primary;
  border-right-color: @primary;
}

.icon {
  width: 2.5em;
  height: 2.5em;

  .rides &,
  .admin &,
  .show &,
  .birthday &,
  .schedule & {
    fill: @grey4;
  }
}

.expand a {
  color: @grey2;
  text-decoration: none;
  font-weight: 600;
}
.show-graphs {
  /*height: 6rem;
  margin-top: 1rem;
  width: 40%;
  text-align: center;*/
  height: 6rem;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  flex: 0 1 60%;
  justify-content: flex-end;

  > div {
    width: 4rem;
    margin: 0 0.25rem;
  }

  .active {
    background: white;
  }
  .inactive {
    background: @menu-background;
  }
}

.panel {
  padding: 2rem 3em;
  color: @grey2;

  &.show,
  &.birthday,
  &.schedule {
    .expand {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.signage {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

ul.horizontal-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li:after {
    content: '|';
    font-size: 1.5rem;
    margin: 0 0.25rem;
  }

  li:last-child:after {
    content: '';
    margin: 0;
  }

  a {
    color: @menu-background;
    font-size: 1.5rem;
  }
}

.schedule-text {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2vw;
  align-items: center;

  h2 {
    margin: 0;
  }
}

.schedule-text-bg {
  background: white;
  color: @menu-background;
  border-radius: 0.5rem;
  padding: 1vh;
}



.grey {
  color: @grey3;
}

.admin {
  height: 100%;
  width: 100%;
}

.admin-links-container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.admin-links {
  padding: 0.1vw !important;
  margin: 0.1vw !important;
  width: 70%;
  height: 4vh;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  color: @rose;
}

.birthdays-wrapper {
  display: flex;
  align-items: center;
}

.birthdays {
  background-image: url('../assets/svg-icons/balloon.svg');
  background-repeat: no-repeat;
  color: @menu-background;
  width: 4.5rem;
  height: 5.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 1.5rem;
  background-size: contain;
  background-position: center;

  h2 {
    margin: 0;
  }
}

.birthday-text {
  h3 {
    margin: 0;
  }
}

@media only screen and (max-width: 1600px) {
  h3 {
    font-size: 1.25rem;
  }

  ul.horizontal-nav a {
    font-size: 1.25rem;
  }

  .birthdays {
    width: 3.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  main.dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    grid-template-areas:
      'status'
      'log';
  }
}

@media only screen and (max-width: 980px) {
  .status {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto;
    margin-right: 0;
  }

  .panel {
    padding: 1rem;
  }

  h3 {
    font-size: 1rem;
  }
}

.panel-footer {
  margin-top: 1rem;
  text-align: right;
}
</style>
