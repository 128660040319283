
export default [
    { id: 'AF', name: 'countries.AF' },
    { id: 'AX', name: 'countries.AX' },
    { id: 'AL', name: 'countries.AL' },
    { id: 'DZ', name: 'countries.DZ' },
    { id: 'AS', name: 'countries.AS' },
    { id: 'AD', name: 'countries.AD' },
    { id: 'AO', name: 'countries.AO' },
    { id: 'AI', name: 'countries.AI' },
    { id: 'AQ', name: 'countries.AQ' },
    { id: 'AG', name: 'countries.AG' },
    { id: 'AR', name: 'countries.AR' },
    { id: 'AM', name: 'countries.AM' },
    { id: 'AW', name: 'countries.AW' },
    { id: 'AU', name: 'countries.AU' },
    { id: 'AT', name: 'countries.AT' },
    { id: 'AZ', name: 'countries.AZ' },
    { id: 'BS', name: 'countries.BS' },
    { id: 'BH', name: 'countries.BH' },
    { id: 'BD', name: 'countries.BD' },
    { id: 'BB', name: 'countries.BB' },
    { id: 'BY', name: 'countries.BY' },
    { id: 'BE', name: 'countries.BE' },
    { id: 'BZ', name: 'countries.BZ' },
    { id: 'BJ', name: 'countries.BJ' },
    { id: 'BM', name: 'countries.BM' },
    { id: 'BT', name: 'countries.BT' },
    { id: 'BO', name: 'countries.BO' },
    { id: 'BQ', name: 'countries.BQ' },
    { id: 'BA', name: 'countries.BA' },
    { id: 'BW', name: 'countries.BW' },
    { id: 'BV', name: 'countries.BV' },
    { id: 'BR', name: 'countries.BR' },
    { id: 'IO', name: 'countries.IO' },
    { id: 'BN', name: 'countries.BN' },
    { id: 'BG', name: 'countries.BG' },
    { id: 'BF', name: 'countries.BF' },
    { id: 'BI', name: 'countries.BI' },
    { id: 'KH', name: 'countries.KH' },
    { id: 'CM', name: 'countries.CM' },
    { id: 'CA', name: 'countries.CA' },
    { id: 'CV', name: 'countries.CV' },
    { id: 'KY', name: 'countries.KY' },
    { id: 'CF', name: 'countries.CF' },
    { id: 'TD', name: 'countries.TD' },
    { id: 'CL', name: 'countries.CL' },
    { id: 'CN', name: 'countries.CN' },
    { id: 'CX', name: 'countries.CX' },
    { id: 'CC', name: 'countries.CC' },
    { id: 'CO', name: 'countries.CO' },
    { id: 'KM', name: 'countries.KM' },
    { id: 'CG', name: 'countries.CG' },
    { id: 'CD', name: 'countries.CD' },
    { id: 'CK', name: 'countries.CK' },
    { id: 'CR', name: 'countries.CR' },
    { id: 'CI', name: 'countries.CI' },
    { id: 'HR', name: 'countries.HR' },
    { id: 'CU', name: 'countries.CU' },
    { id: 'CW', name: 'countries.CW' },
    { id: 'CY', name: 'countries.CY' },
    { id: 'CZ', name: 'countries.CZ' },
    { id: 'DK', name: 'countries.DK' },
    { id: 'DJ', name: 'countries.DJ' },
    { id: 'DM', name: 'countries.DM' },
    { id: 'DO', name: 'countries.DO' },
    { id: 'EC', name: 'countries.EC' },
    { id: 'EG', name: 'countries.EG' },
    { id: 'SV', name: 'countries.SV' },
    { id: 'GQ', name: 'countries.GQ' },
    { id: 'ER', name: 'countries.ER' },
    { id: 'EE', name: 'countries.EE' },
    { id: 'ET', name: 'countries.ET' },
    { id: 'FK', name: 'countries.FK' },
    { id: 'FO', name: 'countries.FO' },
    { id: 'FJ', name: 'countries.FJ' },
    { id: 'FI', name: 'countries.FI' },
    { id: 'FR', name: 'countries.FR' },
    { id: 'GF', name: 'countries.GF' },
    { id: 'PF', name: 'countries.PF' },
    { id: 'TF', name: 'countries.TF' },
    { id: 'GA', name: 'countries.GA' },
    { id: 'GM', name: 'countries.GM' },
    { id: 'GE', name: 'countries.GE' },
    { id: 'DE', name: 'countries.DE' },
    { id: 'GH', name: 'countries.GH' },
    { id: 'GI', name: 'countries.GI' },
    { id: 'GR', name: 'countries.GR' },
    { id: 'GL', name: 'countries.GL' },
    { id: 'GD', name: 'countries.GD' },
    { id: 'GP', name: 'countries.GP' },
    { id: 'GU', name: 'countries.GU' },
    { id: 'GT', name: 'countries.GT' },
    { id: 'GG', name: 'countries.GG' },
    { id: 'GN', name: 'countries.GN' },
    { id: 'GW', name: 'countries.GW' },
    { id: 'GY', name: 'countries.GY' },
    { id: 'HT', name: 'countries.HT' },
    { id: 'HM', name: 'countries.HM' },
    { id: 'VA', name: 'countries.VA' },
    { id: 'HN', name: 'countries.HN' },
    { id: 'HK', name: 'countries.HK' },
    { id: 'HU', name: 'countries.HU' },
    { id: 'IS', name: 'countries.IS' },
    { id: 'IN', name: 'countries.IN' },
    { id: 'ID', name: 'countries.ID' },
    { id: 'IR', name: 'countries.IR' },
    { id: 'IQ', name: 'countries.IQ' },
    { id: 'IE', name: 'countries.IE' },
    { id: 'IM', name: 'countries.IM' },
    { id: 'IL', name: 'countries.IL' },
    { id: 'IT', name: 'countries.IT' },
    { id: 'JM', name: 'countries.JM' },
    { id: 'JP', name: 'countries.JP' },
    { id: 'JE', name: 'countries.JE' },
    { id: 'JO', name: 'countries.JO' },
    { id: 'KZ', name: 'countries.KZ' },
    { id: 'KE', name: 'countries.KE' },
    { id: 'KI', name: 'countries.KI' },
    { id: 'KP', name: 'countries.KP' },
    { id: 'KR', name: 'countries.KR' },
    { id: 'KW', name: 'countries.KW' },
    { id: 'KG', name: 'countries.KG' },
    { id: 'LA', name: 'countries.LA' },
    { id: 'LV', name: 'countries.LV' },
    { id: 'LB', name: 'countries.LB' },
    { id: 'LS', name: 'countries.LS' },
    { id: 'LR', name: 'countries.LR' },
    { id: 'LY', name: 'countries.LY' },
    { id: 'LI', name: 'countries.LI' },
    { id: 'LT', name: 'countries.LT' },
    { id: 'LU', name: 'countries.LU' },
    { id: 'MO', name: 'countries.MO' },
    { id: 'MK', name: 'countries.MK' },
    { id: 'MG', name: 'countries.MG' },
    { id: 'MW', name: 'countries.MW' },
    { id: 'MY', name: 'countries.MY' },
    { id: 'MV', name: 'countries.MV' },
    { id: 'ML', name: 'countries.ML' },
    { id: 'MT', name: 'countries.MT' },
    { id: 'MH', name: 'countries.MH' },
    { id: 'MQ', name: 'countries.MQ' },
    { id: 'MR', name: 'countries.MR' },
    { id: 'MU', name: 'countries.MU' },
    { id: 'YT', name: 'countries.YT' },
    { id: 'MX', name: 'countries.MX' },
    { id: 'FM', name: 'countries.FM' },
    { id: 'MD', name: 'countries.MD' },
    { id: 'MC', name: 'countries.MC' },
    { id: 'MN', name: 'countries.MN' },
    { id: 'ME', name: 'countries.ME' },
    { id: 'MS', name: 'countries.MS' },
    { id: 'MA', name: 'countries.MA' },
    { id: 'MZ', name: 'countries.MZ' },
    { id: 'MM', name: 'countries.MM' },
    { id: 'NA', name: 'countries.NA' },
    { id: 'NR', name: 'countries.NR' },
    { id: 'NP', name: 'countries.NP' },
    { id: 'NL', name: 'countries.NL' },
    { id: 'NC', name: 'countries.NC' },
    { id: 'NZ', name: 'countries.NZ' },
    { id: 'NI', name: 'countries.NI' },
    { id: 'NE', name: 'countries.NE' },
    { id: 'NG', name: 'countries.NG' },
    { id: 'NU', name: 'countries.NU' },
    { id: 'NF', name: 'countries.NF' },
    { id: 'MP', name: 'countries.MP' },
    { id: 'NO', name: 'countries.NO' },
    { id: 'OM', name: 'countries.OM' },
    { id: 'PK', name: 'countries.PK' },
    { id: 'PW', name: 'countries.PW' },
    { id: 'PS', name: 'countries.PS' },
    { id: 'PA', name: 'countries.PA' },
    { id: 'PG', name: 'countries.PG' },
    { id: 'PY', name: 'countries.PY' },
    { id: 'PE', name: 'countries.PE' },
    { id: 'PH', name: 'countries.PH' },
    { id: 'PN', name: 'countries.PN' },
    { id: 'PL', name: 'countries.PL' },
    { id: 'PT', name: 'countries.PT' },
    { id: 'PR', name: 'countries.PR' },
    { id: 'QA', name: 'countries.QA' },
    { id: 'RE', name: 'countries.RE' },
    { id: 'RO', name: 'countries.RO' },
    { id: 'RU', name: 'countries.RU' },
    { id: 'RW', name: 'countries.RW' },
    { id: 'BL', name: 'countries.BL' },
    { id: 'SH', name: 'countries.SH' },
    { id: 'KN', name: 'countries.KN' },
    { id: 'LC', name: 'countries.LC' },
    { id: 'MF', name: 'countries.MF' },
    { id: 'PM', name: 'countries.PM' },
    { id: 'VC', name: 'countries.VC' },
    { id: 'WS', name: 'countries.WS' },
    { id: 'SM', name: 'countries.SM' },
    { id: 'ST', name: 'countries.ST' },
    { id: 'SA', name: 'countries.SA' },
    { id: 'SN', name: 'countries.SN' },
    { id: 'RS', name: 'countries.RS' },
    { id: 'SC', name: 'countries.SC' },
    { id: 'SL', name: 'countries.SL' },
    { id: 'SG', name: 'countries.SG' },
    { id: 'SX', name: 'countries.SX' },
    { id: 'SK', name: 'countries.SK' },
    { id: 'SI', name: 'countries.SI' },
    { id: 'SB', name: 'countries.SB' },
    { id: 'SO', name: 'countries.SO' },
    { id: 'ZA', name: 'countries.ZA' },
    { id: 'GS', name: 'countries.GS' },
    { id: 'ES', name: 'countries.ES' },
    { id: 'LK', name: 'countries.LK' },
    { id: 'SD', name: 'countries.SD' },
    { id: 'SR', name: 'countries.SR' },
    { id: 'SJ', name: 'countries.SJ' },
    { id: 'SZ', name: 'countries.SZ' },
    { id: 'SE', name: 'countries.SE' },
    { id: 'CH', name: 'countries.CH' },
    { id: 'SY', name: 'countries.SY' },
    { id: 'TW', name: 'countries.TW' },
    { id: 'TJ', name: 'countries.TJ' },
    { id: 'TZ', name: 'countries.TZ' },
    { id: 'TH', name: 'countries.TH' },
    { id: 'TL', name: 'countries.TL' },
    { id: 'TG', name: 'countries.TG' },
    { id: 'TK', name: 'countries.TK' },
    { id: 'TO', name: 'countries.TO' },
    { id: 'TT', name: 'countries.TT' },
    { id: 'TN', name: 'countries.TN' },
    { id: 'TR', name: 'countries.TR' },
    { id: 'TM', name: 'countries.TM' },
    { id: 'TC', name: 'countries.TC' },
    { id: 'TV', name: 'countries.TV' },
    { id: 'UG', name: 'countries.UG' },
    { id: 'UA', name: 'countries.UA' },
    { id: 'AE', name: 'countries.AE' },
    { id: 'GB', name: 'countries.GB' },
    { id: 'US', name: 'countries.US' },
    { id: 'UM', name: 'countries.UM' },
    { id: 'UY', name: 'countries.UY' },
    { id: 'UZ', name: 'countries.UZ' },
    { id: 'VU', name: 'countries.VU' },
    { id: 'VE', name: 'countries.VE' },
    { id: 'VN', name: 'countries.VN' },
    { id: 'VG', name: 'countries.VG' },
    { id: 'VI', name: 'countries.VI' },
    { id: 'WF', name: 'countries.WF' },
    { id: 'EH', name: 'countries.EH' },
    { id: 'YE', name: 'countries.YE' },
    { id: 'ZM', name: 'countries.ZM' },
    { id: 'ZW', name: 'countries.ZW'  }
  ];
  