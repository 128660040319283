<template>
  <bt-modal :visible="visible" @close="$emit('close')" :title="title">
    <!-- modal body -->
    <template v-slot:body>
      <el-form
        ref="form"
        :model="formData"
        label-width="120px"
        label-position="top"
      >
        <!-- name -->
        <el-form-item :label="$t('userTypeName')">
          <el-input :disabled="true" :value="formData.name" />
        </el-form-item>

        <!-- claimValue -->
        <el-form-item :label="$t('userTypeClaimValue')">
          <el-input :disabled="true" :value="formData.claimValue" />
        </el-form-item>

        <!-- active switch -->
        <el-form-item :label="$t('active')" prop="active">
          <!-- if userType is admin then no switch shows -->
          <el-switch
            v-if="formData.name != 'admin'"
            :value="formData.active"
            @change="setActive(formData.active, formData.name)"
          >
          </el-switch>
        </el-form-item>
        <!-- Permissions table -->
        <el-form-item :label="$t('permissions')">
          <table class="permission-table">
            <!-- Heading row -->
            <tr>
              <th></th>
              <th scope="col">Read</th>
              <th scope="col">Write</th>
              <th scope="col">Delete</th>
            </tr>

            <tr v-for="(group, idx) in availablePermissions" :key="idx">
              <th scope="row">{{ $t(group.label) }}</th>
              <!-- Get the translation key from the availablePermissions array below -->
              <td>
                <el-switch
                  v-if="group.read"
                  :value="permissionsObj['read' + ':' + group.type]"
                  @change="togglePermission('read' + ':' + group.type)"
                />
              </td>
              <td>
                <el-switch
                  v-if="group.write"
                  :value="permissionsObj['write' + ':' + group.type]"
                  @change="togglePermission('write' + ':' + group.type)"
                />
              </td>
              <td>
                <el-switch
                  v-if="group.delete"
                  :value="permissionsObj['delete' + ':' + group.type]"
                  @change="togglePermission('delete' + ':' + group.type)"
                />
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>
    </template>

    <!-- modal footer -->
    <template v-slot:footer>
      <el-button type="success" @click="$emit('save', formData)">
        {{ $t('save') }}
      </el-button>
      <el-button @click="$emit('close')">
        {{ $t('close') }}
      </el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal'
import { Form, FormItem, Button, Switch, Input, MessageBox } from 'element-ui'

export default {
  name: 'UserTypeEditModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-button': Button,
    'el-input': Input,
    'el-switch': Switch,
  },
  computed: {
    permissionsObj() {
      // Convert the permissions array into an object.
      // [ 'read:ride' ] => { 'read:ride': true }
      if (!this.formData || !this.formData.permissions) return {}

      var result = {}

      this.formData.permissions.forEach((permission) => {
        result[permission] = true
      })

      return result
    },
  },
  data() {
    return {
      title: this.$t('editUserType'),
      formData: null,
      active: false,
      availablePermissions: [
        {
          label: 'rides', // This is a key in the locale files (e.g. en-gb.json)
          type: 'ride', // the second part in the 'read:ride', 'write:ride' etc. strings
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'rideStatuses',
          type: 'ride_status',
          read: true,
          write: true,
        },
        {
          label: 'shows',
          type: 'show',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'showStatuses',
          type: 'show_status',
          read: true,
          write: true,
        },
        {
          label: 'showTypes',
          type: 'show_type',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'showVenues',
          type: 'show_venue',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'fAndB',
          type: 'fandb',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'fAndBStatuses',
          type: 'fandb_status',
          read: true,
          write: true,
        },
        {
          label: 'statusMessages',
          type: 'message',
          write: true,
          delete: true
        },
        {
          label: 'openingHours',
          type: 'schedule',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'birthdays',
          type: 'birthday',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'userTypes',
          type: 'user_type',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'tokens',
          type: 'token',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'sites',
          type: 'site',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'logEntries',
          type: 'log_entry',
          read: true
        }
      ],
    }
  },
  methods: {
    togglePermission(permission) {
      // This function adds the value of the permission property to
      // this.formData.permissions, or takes it away if it is alread there
      if (!permission) return

      // Find the position of the cermission in the array
      var idx = this.formData.permissions.indexOf(permission)

      if (idx === -1) {
        // This permission isn't currently listed in the permissions array. Add it.
        this.formData.permissions.push(permission)
      } else {
        // This permission is listed . Take it out of the array
        this.formData.permissions.splice(idx, 1)
      }
    },
    //TODO: update types: DO NOT EDIT admin!!! Ever!
    setActive(active, name) {
      //avoid bad people disabling the admin
      if (name == 'admin') return

      if (active === true) {
        return MessageBox.confirm(
          this.$t('switchUserTypeOff'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning',
          }
        )
          .then(() => {
            //switch off the button
            this.formData.active = false
          })
          .catch(() => {
            // User has selected cancel, no further action required
          })
      } else {
        this.formData.active = true
      }
    },
  },
  props: {
    userType: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        var data = this.userType ? this.userType : {}

        this.formData = Object.assign(
          {
            name: null,
            // active:null,
            // claimValue:null,
            permissions: {},
          },
          JSON.parse(JSON.stringify(data))
        )
      } else {
        this.formData = null
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
