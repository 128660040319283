<template>
  <div id="app">
    <router-view> </router-view>
    <icon-sprite />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IconSprite from './components/IconSprite'

export default {
  name: 'App',
  components: {
    'icon-sprite': IconSprite,
  },
  created() {
    // Load the user data if a user is currently signed in
    this.init()
  },
  methods: {
    ...mapActions(['init']),
  },
}
</script>

<style lang="less">
@import 'styles/vars';
@import 'styles/icons';
@import (css)
  url('https://fonts.googleapis.com/css?family=Nunito:400,400i,700,700i|Open+Sans:400,400i,700,700i');

*,
*:before,
*:after {
  box-sizing: border-box;
}

button.el-button {
  font-family: @primary-font;
}

html {
  height: 100%;
  //font-size: 112.5%; // 18px base font size
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: @primary-font;
  color: @text-color;
  background: @grey12;
}

#app {
  height: 100vh;
}

h1,
h2,
h3 {
  font-family: @heading-font;
}

textarea {
  font-family: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: @primary;
}

// Global Typography
h1 {
  font-size: 2rem;
  font-weight: normal;
}

h2 {
  font-weight: normal;
  //padding-bottom:3vh;
}

h3 {
  font-size: 1.5em;
  font-weight: lighter;
}

// h1 at the top of each page
.page-heading {
  margin: 0 0 1rem;
}

// Global element styles

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.flex {
  display: flex;
  &.space-between {
    justify-content: space-between;
    align-items: center;
  }
  &.column {
    flex-direction: column;
  }
}

// padding/margin

.padding {
  padding: @padding;
}
.padding-left {
  padding-left: @padding;
}
.padding-right {
  padding-right: @padding;
}
.padding-top {
  padding-top: @padding;
}
.padding-bottom {
  padding-bottom: @padding;
}

.margin {
  margin: @margin;
}
.margin-left {
  margin-left: @margin;
}
.margin-right {
  margin-right: @margin;
}
.margin-top {
  margin-top: @margin;
}
.margin-bottom {
  margin-bottom: @margin;
}

// Encloses the contents of each feature page (e.g. rides)
main.list-page {
  background: white;
  box-shadow: 2px 2px 2px black;
}

// Generic panel class used to enclose information
.panel {
  background: white;
  box-shadow: @panel-shadow;
  padding: 1em 1.5em;
  border-radius: 1rem;
  position: relative;

  h2 {
    font-size: 1.5rem;
  }

  h2:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  // Panel variants intended for specific parts of the site
  &.schedule {
    background: @schedules-gradient;
    color: white;
  }

  &.show {
    background: @shows-gradient;
    color: white;
  }

  &.birthday {
    background: @birthdays-gradient;
    color: white;
  }
}

// A number of pages feature a header section with a 'new' button and search field
// at the top. These classes can be useto wrap the section as a whole and the search
// field (see AdminTokens.vue for an example)
.headSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-search {
  flex: 0 1 20rem;
}
main.main-container {
  background: white;
  padding: 2rem;
}
.el-alert /deep/ .el-alert__content {
  width: 100%;
}

// modal styles
.permission-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8125rem;
}

tr {
  border-bottom: 1px solid #ccc;
}

th[scope='row'] {
  font-weight: normal;
  text-align: left;
}

th[scope='row'],
td {
  padding: 0.5rem 0;
}

td {
  text-align: center;
}

.status-wrapper {
  display: grid;
  grid-gap: 1.5rem;
  margin-bottom: 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

// Fade transition. See: https://vuejs.org/v2/guide/transitions.html
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.el-popover {
  // Reset the word break rule for element ui popovers
  word-break: normal !important;
  text-align: left !important;
}

// Add this class to el-tooltip elements when used in a group of buttons
.button-tooltip + .button-tooltip {
  margin: 0 0 0 0.5rem;
}

// Sticky footer for rides, shows etc.
.page-footer {
  bottom: 2rem; // Was 0
  margin-top: 2rem;
  z-index: 50;
  position: sticky;
  width: 100%;

  .footer-inner {
    margin: 0 -1.5rem -2rem;
    background: white;
    padding: 1em 1.5em;
    border-top: 1px solid #eee;
  }
}

// Unsaved notice for rides, show etc.
.unsaved-notice {
  margin-left: 1rem;
  color: @orange;

  & > i {
    margin-right: 0.5rem;
  }

  & > i,
  & > span {
    vertical-align: middle;
  }
}

// Page heading for rides, shows etc.
.page-heading {
  border-bottom: 1px solid @grey9;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;

  svg {
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 0.5rem;
  }
}

.resource-type {
  color: #409eff;
  margin: 1.5rem 0 1rem;
}

// Used to place a question mark icon next to form items (for popup descriptions)
.explanation {
  color: @blue;
  vertical-align: middle;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .headSection {
    flex-direction: column;
  }
  .headSection button {
    margin-bottom: 1rem;
  }
  .headSection .header-search {
    width: 100%;
    flex: 0;
  }
  //overwrite element ui messagebox

  .el-message-box {
    width: 90vw !important;
  }
  .el-message{
    min-width: 90vw !important;
  }
}
</style>
