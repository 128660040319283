<template>
  <div class="birthday-calendar">
    <el-calendar :value="value" @input="selectDay">
      <template v-slot:dateCell="{date, data}">
        <div class="day-inner" :class="{ 'is-selected': data.isSelected, 'past': isPast(date, data) }" >
          {{ date.getDate() }}

          <!-- Display any current birthdays for this day -->
          <span v-if="birthdays[getDateKey(date)]" class="record-count">
            {{ birthdays[getDateKey(date)].length }}
          </span>
        </div>
      </template>
    </el-calendar>
  </div>
</template>

<script>
import { Calendar } from 'element-ui';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'BirthdayCalendar',
  components: {
    'el-calendar': Calendar
  },
  computed: {
    ...mapGetters(['currentSite']),
  },
  created() {
  },
  methods: {
    selectDay(date) {
      if (this.isPast(date)) date = new Date(); // If the date is in the past select the current day instead
      this.$emit('input', date);
    },
    getDateKey(date) {
      // Returns a simple date key string
      var dateObj = DateTime.fromJSDate(date);
      return dateObj.toFormat('yyyy-MM-dd');
    },
    isPast(date) {
      // Returns a boolean indicating if a date is in the past.
      // NOTE: When selecting a date, el-calendar emits a Date object representing
      // the start of the selected day in UTC time.

      // 1. Convert the date into a Luxon DateTime object
      var utcDate = DateTime.fromJSDate(date, { zone: 'utc' });

      // 2. Use this to find the end of the selected day in the site's local timezone
      var localDate = utcDate
        .setZone(this.currentSite.timeZone, { keepLocalTime: true })
        .endOf('day');

      // 3. Get the current time
      var now = DateTime.local();

      // 4. Compare the end of the selected day with the local time
      return now > localDate;
    },
  },
  props: {
    birthdays: Object,
    value: Date // The currently selected date
  }
}
</script>

<style lang="less" scoped>
.day-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em;
}

.past {
  color: #c0c4cc;
  background: #fafafa;
  cursor: auto;
}

// Fix for birthday calendar
.birthday-calendar /deep/ .el-calendar-day {
  position: relative;
}

.record-count {
  background: #8BC34A;
  color: white;
  height: 2em;
  display: inline-block;
  padding: 0.5em;
  line-height: 1;
  font-size: 1rem;
  border-radius: 2em;
  min-width: 2em;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.next .record-count {
  opacity: 0.5;
}

.birthday-calendar /deep/ td {
  padding: 0;
}
</style>