<template>
  <div class="admin admin-sites view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#gear"></use>
      </svg>
      <span>
        {{ $t('sites') }}
      </span>
    </h1>

    <div class="headSection">
      <!-- button to add new site -->
      <el-button @click="addNewSite" round type="primary" icon="si-plus">
        {{ $t('addNewSite') }}
      </el-button>

      <!-- filter here -->
      <div class="header-search">
        <el-input
          v-model="search"
          size="medium"
          :placeholder="$t('typeToSearch')"
        />
      </div>
    </div>

    <main class="main-container panel">
      <!-- existing sites table here  -->
      <template>
        <el-table
          :data="
            sites.filter(
              (sites) =>
                !search ||
                sites.name.toLowerCase().includes(search.toLowerCase())
            )
          "
          :default-sort="{ prop: 'modified', order: 'descending' }"
          style="width: 100%"
          v-if="sites"
        >
          <!-- Name column -->
          <el-table-column prop="name" :label="$t('name')"> </el-table-column>

          <!-- Timezone column -->
          <el-table-column prop="timeZone" :label="$t('timeZone')">
          </el-table-column>

          <!-- Created column -->
          <el-table-column
            prop="created"
            :label="$t('created')"
            :formatter="dateFormatter"
          >
          </el-table-column>
          <!-- Modified column -->
          <el-table-column
            prop="modified"
            :label="$t('modified')"
            :formatter="dateFormatter"
          >
          </el-table-column>

          <!-- Acive switch -->
          <el-table-column
            :label="$t('active')"
            prop="active"
            width="70vw"
            align="left"
          >
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.active"
                @change="
                  setActive(scope.row.active, scope.row.id, scope.row.name)
                "
              />
            </template>
          </el-table-column>

          <!-- edit button -->
          <el-table-column width="90vw" align="left">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('editSite')"
                placement="right-start"
              >
                <el-button
                  v-if="scope.row.name != 'admin'"
                  size="mini"
                  @click="editSite(scope.row)"
                >
                  <i class="button-icon si-edit"></i>
                  <span class="button-text">{{ $t('edit') }}</span>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <!-- delete button -->
          <el-table-column width="150vw" align="left">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                :content="$t('deleteSite')"
                placement="right-start"
              >
                <el-button
                  v-if="scope.row.name != 'admin'"
                  size="mini"
                  type="danger"
                  @click="deleteSite(scope.row)"
                >
                  <i class="button-icon si-trash"></i>
                  <span class="button-text">{{ $t('delete') }}</span>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </main>

    <!-- modal open here -->
    <!-- later add this for unique key
         :names="names" -->
    <admin-site-modal
      :visible="adminSiteModalOpen"
      :editing="editing"
      @close="closeModal"
      @save="saveSite"
      :site="selectedSite"
    />
  </div>
</template>

<script>
import api from '../api';
import Loading from '../mixins/Loading';
import {
  Input,
  Table,
  TableColumn,
  Switch,
  Tooltip,
  Message,
  MessageBox,
} from 'element-ui';
import AdminSiteModal from '../components/admin_sites/AdminSiteModal.vue';

export default {
  created() {
    this.loadSites();
  },
  name: 'AdminSites',
  data() {
    return {
      sites: null,
      search: '',
      adminSiteModalOpen: false,
      site: null,
      selectedSite: null,
      editing: false, // Is the user currently editing a schedule?
    }
  },
  components: {
    'el-input': Input,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-switch': Switch,
    'admin-site-modal': AdminSiteModal,
    'el-tooltip': Tooltip,
  },
  computed: {
    names() {
      if (!this.sites) return []
      // get an array of existing values in the db of claimMalue to
      // avoid saving duplicate
      // if there is nothing in the table return empty array
      return this.sites.map((site) => site.name)
    }
  },
  methods: {
    loadSites() {
      this.startLoading()
      return api
        .getSites()
        .then((response) => {
          this.sites = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
        })
    },
    closeModal() {
      this.editing = false
      this.selectedSite= null
      this.adminSiteModalOpen = false
    },
    dateFormatter(row) {
      return this.$d(new Date(row.modified), 'mediumWithTime')
    },
    //modal open
    addNewSite() {
      this.selectedSite= null
      this.adminSiteModalOpen = true
    },
    //modal open
    editSite(selectedSite) {
      this.editing = true
      this.adminSiteModalOpen = true
      this.selectedSite = selectedSite
    },

    setActive(active, id) {
      var bool = false
      //reverse set the value to be sent to the api
      if (!active) {
        bool = true
      }
      var updateData = {
        active: bool,
      }
      api.updateSite(id, updateData).then((SiteData) => {
        this.loadSites()
        this.sites = SiteData.value
        Message({
          message: this.$t('siteSuccessfullyUpdated'),
          type: 'success',
        })
      })
    },
    saveSite(siteData, isEdit) {
      this.adminSiteModalOpen = false
      var request
      if (isEdit) {
        request = api.updateSite(siteData.id, siteData)
      } else {
        request = api.createSite(siteData)
      }
      this.startLoading()
      return request
        .then(() => {
          var message
          if (isEdit) {
            message = this.$t('savedChangesToSite', { siteName: siteData.name })
          } else {
            message = this.$t('createdSite', { siteName: siteData.name })
          }
          Message({
            message: message,
            type: 'success',
          })
          return this.loadSites()
        })
        .catch(() => {
          Message({
            message: this.$t('errorSavingSite'),
            type: 'error',
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    deleteSite(selectedRow) {
      return (
        MessageBox.confirm(
          this.$t('deleteSiteConfirmMessage'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning',
          }
        )
          //if accepted, send api request
          .then(() => {
            return api
              .deleteSite(selectedRow.id)
              .then(() => {
                this.loadSites()
                Message({
                  message: this.$t('deleteCompleted'),
                  type: 'success',
                })
              })
              .catch(() => {
                Message({
                  message: this.$t('deleteCancelled'),
                  type: 'info',
                })
              })
          })
          .catch(() => {
            // User has selected cancel, no further action required
          })
      )
    }
  },
  mixins: [Loading],
}
</script>
<style lang="less" scoped>
.el-table /deep/ .cell {
  padding: 0.5vw;
  font-size: 13px;
}
.el-form /deep/ .el-form-item__label {
  padding: 0;
}

.button-text {
  margin: 0 !important; // Don't add a margin between the icon and text
}

.button-icon {
  display: none;
}

.headSection {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
  .button-text {
    display: none;
  }

  .button-icon {
    display: inline;
  }
}
</style>
