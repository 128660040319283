import { render, staticRenderFns } from "./SetAllVendorsModal.vue?vue&type=template&id=3dfccc0e&scoped=true&"
import script from "./SetAllVendorsModal.vue?vue&type=script&lang=js&"
export * from "./SetAllVendorsModal.vue?vue&type=script&lang=js&"
import style0 from "./SetAllVendorsModal.vue?vue&type=style&index=0&id=3dfccc0e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dfccc0e",
  null
  
)

export default component.exports