<template>
  <bt-modal :visible="visible" @close="close" :title="modalTitle">
    <template v-slot:body>
      <el-form :rules="rules" label-position="top" :model="formData" ref="form">
        <!-- name -->
        <el-form-item prop="name" :label="$t('siteName')">
          <el-input
            :placeholder="$t('siteName')"
            v-model="formData.name"
          ></el-input>
        </el-form-item>

        <!-- claimValue -->
        <el-form-item prop="claimValue">
          <template v-slot:label>
            <span>{{ $t('siteRoleValue') }}</span>
            <el-popover
              width="400"
              trigger="hover"
              :content="$t('userClaimDescription')"
            >
              <template v-slot:reference>
                <i class="si-question-circle explanation" />
              </template>
            </el-popover>
          </template>

          <el-input
            :placeholder="$t('siteRoleValue')"
            v-model="claimValueEntry"
          >
            <template slot="prepend">site:</template>
          </el-input>
        </el-form-item>

        <!-- Timezones select -->
        <el-form-item :label="$t('timeZone')" prop="timeZone">
          <el-select filterable v-model="formData.timeZone">
            <el-option
              v-for="timeZone in timeZones"
              :value="timeZone.id"
              :key="timeZone.id"
              placeholder="$t('selectTimeZone')"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- Countries select -->
        <el-form-item :label="$t('country')" prop="country">
          <el-select filterable v-model="formData.country">
            <el-option
              v-for="country in countries"
              :label="$t('countries.' + country.id)"
              :value="country.id"
              :key="country.id"
              placeholder="Select Country"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- address data -->
        <el-form-item :label="$t('address1')" prop="address1">
          <el-input
            :placeholder="$t('address1')"
            v-model="formData.address1"
            ref="address1"
          />
        </el-form-item>

        <el-form-item :label="$t('address2')" prop="address2">
          <el-input
            :placeholder="$t('address2')"
            v-model="formData.address2"
            ref="address2"
          />
        </el-form-item>

        <el-form-item :label="$t('town')" prop="town">
          <el-input
            :placeholder="$t('town')"
            v-model="formData.town"
            ref="town"
          />
        </el-form-item>

        <el-form-item :label="$t('county')" prop="county">
          <el-input
            :placeholder="$t('county')"
            v-model="formData.county"
            ref="county"
          />
        </el-form-item>

        <el-form-item :label="$t('postcode')" prop="postcode">
          <el-input
            :placeholder="$t('postcode')"
            v-model="formData.postcode"
            ref="postcode"
          />
        </el-form-item>

        <!-- siteFeatures : [] -->
        <div class="siteFeatures-wrapper">
          <el-form-item :label="$t('birthdays')" prop="siteFeatures.birthdays">
            <el-switch
              v-model="formData.siteFeatures.birthdays"
              :value="formData.siteFeatures.birthdays"
            />
          </el-form-item>
          <el-form-item
            :label="$t('foodVendors')"
            prop="siteFeatures.foodVendors"
          >
            <el-switch
              v-model="formData.siteFeatures.foodVendors"
              :value="formData.siteFeatures.foodVendors"
            />
          </el-form-item>
          <el-form-item :label="$t('rides')" prop="siteFeatures.rides">
            <el-switch
              v-model="formData.siteFeatures.rides"
              :value="formData.siteFeatures.rides"
            />
          </el-form-item>
          <el-form-item :label="$t('shows')" prop="siteFeatures.shows">
            <el-switch
              v-model="formData.siteFeatures.shows"
              :value="formData.siteFeatures.shows"
            />
          </el-form-item>
        </div>

        <!-- blipEnabled  -->
        <el-form-item :label="$t('blipEnabled')" prop="blipEnabled">
          <el-switch
            v-model="formData.blipEnabled"
            :value="formData.blipEnabled"
          />
        </el-form-item>

        <!-- attractions.io enabled  -->
        <el-form-item :label="$t('attractionsIoEnabled')" prop="attractionsIoEnabled">
          <el-switch
            v-model="formData.attractionsIoEnabled"
            :value="formData.attractionsIoEnabled"
          />
        </el-form-item>

        <!-- attractionsIoKey -->
        <el-form-item :label="$t('attractionsIoKey')" prop="attractionsIoKey">
          <el-input
            :placeholder="$t('attractionsIoKey')"
            v-model="formData.attractionsIoKey"
            ref="attractionsIoKey"
          />
        </el-form-item>

        <!-- blipEndpoint -->
        <el-form-item :label="$t('blipEndpoint')" prop="blipEndpoint">
          <el-input
            :placeholder="$t('blipEndpoint')"
            v-model="formData.blipEndpoint"
            ref="blipEndpoint"
          />
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">
        {{ $t('save') }}
      </el-button>
      <el-button @click="close">
        {{ $t('close') }}
      </el-button>
    </template>
  </bt-modal>
</template>

<script>
import { Form, FormItem, Input, Option, Select, Switch, Popover } from 'element-ui'
import BtModal from '../BtModal'
import TimeZones from '../../models/data/timezones.js'
import Countries from '../../models/data/countries.js'

export default {
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-select': Select,
    'el-option': Option,
    'el-switch': Switch,
    'el-popover': Popover
  },
  computed: {
    claimValueEntry: {
      get() {
        if (!this.formData || !this.formData.claimValue) return null;

        // Remove the 'site:' from the start
        if (this.formData.claimValue.substr(0, 5) === 'site:') {
          return this.formData.claimValue.substr(5);
        } else {
          return this.formData.claimValue;
        }
      },
      set(newValue) {
        // Add site at the start
        /*if (newValue.substr(0, 5) === 'site:') {

        }*/
        this.formData.claimValue = 'site:' + newValue;
      }
    },
    modalTitle() {
      if (!this.formData || !this.formData.name) {
        return this.$t('unnamedSite');
      } else {
        return this.formData.name;
      }
    }
  },
  data() {
    return {
      formData: null,
      timeZones: TimeZones,
      countries: Countries,
      dataCopied: false,
      unsavedChanges: false, // Are there crrently unsaved changes within this modal?
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pleaseEnterASiteName'),
            trigger: 'blur',
          },
        ],
        // validate claimValue, has to match pattern  /^site:\\S+$/
        claimValue: [
          {
            required: true,
            message: this.$t('pleaseEnterARole'),
            trigger: 'blur',
          },
          {
            pattern: /^\S+$/,
            message: this.$t('ClaimValuesValidation'),
            trigger: 'blur',
          },
        ],
      },
    }
  },

  props: {
    site: Object,
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    editing: {
      // Are we editing an existing ride or creating a new one?
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      /* required fields (15/03/2021): name, claimValue

        default data by the api:
        timeZone: Europe/London

        required in the form:
        timeZone:        -- select from timezone.js -> store name
        country:         -- select from countries.js -> store id

        address1, address2, town, county, postcode: ''     -- string

        siteFeatures:  -- switches
        [
            birthdays: true
            foodVendors: true
            rides: true
            shows: true
        ]

        not required in the form:
        active: true
        */

      // claimValue string validation
      /*var role = this.formData.claimValue
      if (role.substr(0, 5) !== 'site:') {
        role = 'site:' + role
      }
      // claimValues cannot be the text: 'site:' or any accidental data
      if (role === 'site:' || role === 'site:site:') {
        role = ''
        return
      }*/

      //this.formData.claimValue = role
      return this.$refs.form
        .validate()
        .then(() => {
          this.$emit('save', this.formData, this.editing)
        })
        .catch(() => {
          // User cancelled or validation failed
        })
    },
  },
  watch: {
    formData: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;
      if (this.visible) {
        var data = this.site && this.editing ? this.site : {}
        // When the modal is opened reset the data
        // Check this.editing
        this.formData = Object.assign(
          {
            name: undefined,
            claimValue: undefined,
            timeZone: 'Europe/London',
            country: 'GB',
            address1: undefined,
            address2: undefined,
            town: undefined,
            county: undefined,
            postcode: undefined,
            siteFeatures: {
              birthdays: true,
              foodVendors: true,
              rides: true,
              shows: true,
            },
            blipEnabled: false,
            blipEndpoint: undefined
          },
          JSON.parse(JSON.stringify(data))
        )
      } else {
        // Othewise reset the data
        this.formData = null
      }
      this.unsavedChanges = false;

    },
  },
}
</script>

<style lang="less" scoped>
@import '../../styles/vars';

.siteFeatures-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3vh;
}

.siteFeatures-wrapper /deep/ .el-form-item__label {
  padding: 0;
}
</style>
