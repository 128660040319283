import firebase from 'firebase/app';

export default class AttractionsSDK {
  constructor(baseUrl, opts = {}) {
    if (!baseUrl) throw new Error('No base URL provided')
    this.baseUrl = baseUrl;
    if (opts.token) this.token = opts.token;
  }

  /**
   * Birthdays
   */

  getBirthdays(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays`,
      'GET',
      qs
    );
  }

  createBirthday(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays`,
      'POST',
      null,
      data
    );
  }

  getBirthday(siteId, birthdayId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays/${birthdayId}`,
      'GET',
      qs
    );
  }

  updateBirthday(siteId, birthdayId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays/${birthdayId}`,
      'PATCH',
      null,
      data
    );
  }

  bulkUpdateBirthdays(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays`,
      'PATCH',
      null,
      data
    );
  }

  deleteBirthday(siteId, birthdayId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays/${birthdayId}`,
      'DELETE'
    );
  }

  bulkDeleteBirthdays(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/birthdays`,
      'DELETE',
      qs
    );
  }

  /**
   * Food Vendors
   */

  getVendors(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors`,
      'GET',
      qs
    );
  }

  createVendor(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors`,
      'POST',
      null,
      data
    );
  }

  getVendor(siteId, vendorId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors/${vendorId}`,
      'GET',
      qs
    );
  }

  updateVendor(siteId, vendorId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors/${vendorId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteVendor(siteId, vendorId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors/${vendorId}`,
      'DELETE'
    );
  }

  getVendorStatus(siteId, vendorId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors/${vendorId}/status`,
      'GET',
      qs
    );
  }

  setVendorStatus(siteId, vendorId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/food-vendors/${vendorId}/status`,
      'PUT',
      null,
      data
    );
  }

  /**
   * Status messages
   */

   getStatuses(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/status-messages`,
      'GET',
      qs
    );
  }

  createStatus(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/status-messages`,
      'POST',
      null,
      data
    )
  }

  updateStatus(siteId, statusId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/status-messages/${statusId}`,
      'PATCH',
      null,
      data
    )
  }

  deleteStatus(siteId, statusId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/status-messages/${statusId}`,
      'DELETE'
    )
  }

  /**
   * Rides
   */

  getRides(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides`,
      'GET',
      qs
    );
  }

  createRide(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides`,
      'POST',
      null,
      data
    );
  }

  getRide(siteId, rideId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides/${rideId}`,
      'GET',
      qs
    );
  }

  updateRide(siteId, rideId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides/${rideId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteRide(siteId, rideId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides/${rideId}`,
      'DELETE'
    );
  }

  getRideStatus(siteId, rideId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides/${rideId}/status`,
      'GET',
      qs
    );
  }

  setRideStatus(siteId, rideId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/rides/${rideId}/status`,
      'PUT',
      null,
      data
    );
  }

  /**
   * Schedules
   */

  getSchedules(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/opening-schedules`,
      'GET',
      qs
    );
  }

  createSchedule(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/opening-schedules`,
      'POST',
      null,
      data
    );
  }

  getSchedule(siteId, scheduleId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/opening-schedules/${scheduleId}`,
      'GET',
      qs
    );
  }

  updateSchedule(siteId, scheduleId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/opening-schedules/${scheduleId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteSchedule(siteId, scheduleId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/opening-schedules/${scheduleId}`,
      'DELETE'
    );
  }

  /**
   * Shows
   */

  getShows(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows`,
      'GET',
      qs
    );
  }

  createShow(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows`,
      'POST',
      null,
      data,
      true // isMultipart
    );
  }

  getShow(siteId, showId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows/${showId}`,
      'GET',
      qs
    );
  }

  updateShow(siteId, showId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows/${showId}`,
      'PATCH',
      null,
      data,
      true // isMultipart
    );
  }

  deleteShow(siteId, showId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows/${showId}`,
      'DELETE'
    );
  }

  getShowStatus(siteId, showId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows/${showId}/status`,
      'GET',
      qs
    );
  }

  setShowStatus(siteId, showId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/shows/${showId}/status`,
      'PUT',
      null,
      data
    );
  }

  /**
   * Show types
   */

  getShowTypes(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-types`,
      'GET',
      qs
    );
  }

  createShowType(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-types`,
      'POST',
      null,
      data
    );
  }

  getShowType(siteId, showTypeId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-types/${showTypeId}`,
      'GET',
      qs
    );
  }

  updateShowType(siteId, showTypeId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-types/${showTypeId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteShowType(siteId, showTypeId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-types/${showTypeId}`,
      'DELETE'
    );
  }

  /**
   * Show venues
   */

  getShowVenues(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-venues`,
      'GET',
      qs
    );
  }

  createShowVenue(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-venues`,
      'POST',
      null,
      data
    );
  }

  getShowVenue(siteId, showVenueId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-venues/${showVenueId}`,
      'GET',
      qs
    );
  }

  updateShowVenue(siteId, showVenueId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-venues/${showVenueId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteShowVenue(siteId, showVenueId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}/show-venues/${showVenueId}`,
      'DELETE'
    );
  }

  /**
   * Log
   */

  getLogEntries(qs) {
    return this._request(
      `${this.baseUrl}/log`,
      'GET',
      qs
    );
  }

  /**
   * Sites
   */

  getSites(qs) {
    return this._request(
      `${this.baseUrl}/sites`,
      'GET',
      qs
    );
  }

  createSite(data) {
    return this._request(
      `${this.baseUrl}/sites`,
      'POST',
      null,
      data
    );
  }

  getSite(siteId, qs) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}`,
      'GET',
      qs
    );
  }

  updateSite(siteId, data) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteSite(siteId) {
    return this._request(
      `${this.baseUrl}/sites/${siteId}`,
      'DELETE'
    );
  }

  /**
   * Tokens
   */

  getTokens(qs) {
    return this._request(
      `${this.baseUrl}/tokens`,
      'GET',
      qs
    );
  }

  createToken(data) {
    return this._request(
      `${this.baseUrl}/tokens`,
      'POST',
      null,
      data
    );
  }

  getToken(tokenId, qs) {
    return this._request(
      `${this.baseUrl}/tokens/${tokenId}`,
      'GET',
      qs
    );
  }

  deleteToken(tokenId) {
    return this._request(
      `${this.baseUrl}/tokens/${tokenId}`,
      'DELETE'
    );
  }

  /**
   * Users
   */

  getUsers(qs) {
    return this._request(
      `${this.baseUrl}/users`,
      'GET',
      qs
    );
  }

  getUser(userId, qs) {
    return this._request(
      `${this.baseUrl}/users/${userId}`,
      'GET',
      qs
    );
  }

  /**
   * User types
   */

  getUserTypes(qs) {
    return this._request(
      `${this.baseUrl}/user-types`,
      'GET',
      qs
    );
  }

  createUserType(data) {
    return this._request(
      `${this.baseUrl}/user-types`,
      'POST',
      null,
      data
    );
  }

  getUserType(userTypeId, qs) {
    return this._request(
      `${this.baseUrl}/user-types/${userTypeId}`,
      'GET',
      qs
    );
  }

  updateUserType(userTypeId, data) {
    return this._request(
      `${this.baseUrl}/user-types/${userTypeId}`,
      'PATCH',
      null,
      data
    );
  }

  deleteUserType(userTypeId) {
    return this._request(
      `${this.baseUrl}/user-types/${userTypeId}`,
      'DELETE'
    );
  }

  getMe() {
    return this._request(
      `${this.baseUrl}/auth/me`,
      'GET'
    );
  }

  login(data) {
    return this._request(
      `${this.baseUrl}/auth/login`,
      'POST',
      null,
      data
    );
  }

  // Utility dunction for making a GET request to a full URL. Useful for getting
  // the next page of paginated results
  getUrl(url) {
    return this._request(
      url, 'GET'
    );
  }

  // Remove null and undefined values from an object
  /*_removeEmpty(obj) {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
      if (v === Object(v)) {
        newObj[k] = this._removeEmpty(v);
      } else if (v != null) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  }*/

  // Serialize an object as a query string
  _serialize(obj) {
    var str = [];
    for (var p in obj) {
      if (obj[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }

    if (str.length > 0) {
      return '?' + str.join('&');
    }
  }

  // Create a FormData object
  _packData(data, formData, namespace) {
    var fd = formData || new FormData();

    for(var key in data) {
      if (typeof data[key] !== 'undefined') { // Ignore undefined fields
        let formKey;

        if(namespace) {
          formKey = namespace + '[' + key + ']';
        } else {
          formKey = key;
        }

        if(typeof data[key] === 'object' && !(data[key] instanceof File)) {
          this._packData(data[key], fd, formKey);
        } else {

          // if it's a string or a File object
          fd.append(formKey, data[key]);
        }
      }
    }

    return fd;
  }

  async _request(url, method, qs, body, isMultipart) {
    // Start building the headers
    var headers = {};

    // Set the content type of POST, PATCH or PUT requests
    if (
      !isMultipart &&
      (method === 'POST' || method === 'PATCH' || method === 'PUT')
    ) {
      headers['Content-Type'] = 'application/json';
    }

    if (this.token) {
      // If a token has been provided add that to the headers
      headers['X-API-KEY'] = this.token;
    } else {
      // Otherwise attempt to get a Firebase auth token
      try {
        const token = await firebase.auth().currentUser.getIdToken();
        headers['Authorization'] = 'Bearer ' + token;
      } catch(err) {
        // Failed to get an authentication token
        throw new Error('No API authentication');
      }
    }

    // Build the request options
    var opts = {
      method: method || 'GET',
      headers: headers,
      mode: 'cors'
    };

    if (body) {
      if (isMultipart) {
        opts.body = this._packData(body);
      } else {
        opts.body = JSON.stringify(body);
      }
      //opts.body = JSON.stringify(this._removeEmpty(body));
    }

    // If a query string is required serialize the object and append it to the url
    if (qs) {
      url += this._serialize(qs);
    }

    try {
      const res = await fetch(url, opts);

      if (!res.ok) {
        var err = new Error(res.statusText);
        err.status = res.status;
        throw err;
      }

      // If we have a 204 response the json method will throw an error
      if (res.status === 204) return '';

      return res.json();
    } catch(err) {
      // If no status is set assume a 500 error
      if (!err.status) err.status = 500;
      throw err;
    }
  }
}