<template>
  <transition name="modal-fade" @before-enter="modalVisible = true">
    <div class="modal-backdrop" @mousedown="closeStart" @mouseup="closeEnd" v-if="visible" ref="background">
      <transition name="slow-fade">
        <!--
          Note: The mouseup and mousedown handlers on .modal use the .stop
          modifier to prevent these events from propergating to the parent.
        -->
        <div class="modal"
          :class="size"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
          v-if="modalVisible"
        >
          <header
            class="modal-header"
            id="modalTitle"
          >
            <h2 v-if="title">{{ title }}</h2>

            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
              v-if="closeButton"
            >
              &times;
            </button>
          </header>
          <section
            class="modal-body"
            id="modalDescription"
          >
            <slot name="body">
            </slot>
          </section>
          <footer class="modal-footer" v-if="$slots.footer">
            <slot name="footer">
            </slot>
          </footer>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BtModal',
  data() {
    return {
      modalVisible: false,
      clickedOnBackground: false // Has the mousedown event fired on the background
    }
  },
  props: {
    title: String,
    visible: Boolean,
    closeButton: {
      // Should a close button appear at the top right?
      type: Boolean,
      default: true
    },
    size: {
      // Only large is supported so far
      type: String,
      validator: value => {
        // TODO: Add more sizes
        return ['default', 'large'].indexOf(value) !== -1
      }
    }
  },
  methods: {
    close() {
      //this.modalVisible = false;
      this.$emit('close');
    },
    closeStart(e) {
      // Record when the mousedown event occurs on the background
      // Confirm that the user clicked directly on the background
      if (e.target === this.$refs.background) {
        this.clickedOnBackground = true;
      }
    },
    closeEnd(e) {
      // When the mouseup event occurs we should close the modal if both the
      // mousedown and mouseup event occurs on the background
      if (this.clickedOnBackground === true && e.target === this.$refs.background) {
        this.$emit('close');
      }
      this.clickedOnBackground = false;
    }
  },
  watch: {
    visible() {
      // When a parent component sets visible to false update this.modalVisible
      this.modalVisible = false;
    }
  }
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: @panel-shadow;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  min-width: 30rem;
  max-height: 95vh;

  &.large {
    // Optional larger width
    min-width: 45rem;
    //max-width: 45rem;
  }
}

.modal-header,
.modal-footer {
  padding: 1em;
  display: flex;
  flex: 0 0 auto;
}

.modal-header {
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
}

.modal-footer {
  justify-content: center;
}

.modal-body {
  position: relative;
  padding: 1rem;
  overflow-y: auto;
  flex: 0 1 auto; // Allow the body to overflow when required
}

.btn-close {
  font-size: 2rem;
  border: none;
  width: 2.75rem;
  height: 2.75rem;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .4s ease
}

.slow-fade-enter,
.slow-fade-leave-active {
  opacity: 0;

  transform: translateY(-15%);
}

.slow-fade-enter-active,
.slow-fade-leave-active {
  transition: opacity .4s ease, transform .4s ease;
}

@media only screen and (max-width: 600px) {
  .modal-backdrop {
    // Position the modal at the top of the screen
    align-items: flex-end;
  }

  .modal {
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-width: auto;
    max-width: 100%; // Allow the modal to fill the full width of small screens
    max-height: 80vh; // Prevent the modal from taking up the full height of the screen
  }

  .slow-fade-enter,
  .slow-fade-leave-active {
    transform: translateY(100%);
  }
}
</style>