<template>
  <li class="bt-sub-menu" :class="{ 'open': open && !closing }">
    <button @click="toggleMenu" class="bt-menu-item">
      <span class="text">
        <slot name="title"></slot>
      </span>

      <span class="icon arrow">
        <i class="si-arrow-down"></i>
      </span>
    </button>
    <ul
      class="nested"
      ref="subMenu"
      :hidden="!open"
      @transitionend="clearTransition"
      :style="{ 'height': menuHeight }"
    >
      <slot></slot>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'BtSubMenu',
  data() {
    return {
      open: false, // Sub menu is currently open
      menuHeight: null, // Used to control the height of the menus during opening/closing transitions
      closing: false // Sub menu is currently closing
    }
  },
  methods: {
    toggleMenu() {
      if (this.open) {
        // Close the menu
        this.closing = true;
        this.menuHeight = this.$refs.subMenu.scrollHeight + 'px';
        window.setTimeout(() => {
          this.menuHeight = 0;
        }, 0);
      } else {
        // Open the menu
        this.open = true; // Removes the hidden attibute
        this.menuHeight = 0;
        window.setTimeout(() => {
          this.menuHeight = this.$refs.subMenu.scrollHeight + 'px';
        }, 0);
      }
    },
    clearTransition() {
      if (this.closing) {
        this.closing = false;
        this.open = false;
      }
      //this.$refs.subMenu.style.height = null;
      this.menuheight = null;
    }
  }
}
</script>

<style lang="less" scoped>
button {
  height: 3rem;
  width: 100%;
  text-align: left;
  background: transparent;
  color: white;
  fill: white;
  border: none;
  border-left: 0.25rem solid transparent;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(65, 104, 212, 0.5);
  }
}

.nested {
  overflow: hidden;
  transition: height 0.4s ease;
  margin: 0;
  list-style: none;
  padding-left: 0.6rem;
}

.text {
  flex: 1 1 auto;
}

.arrow {
  margin-right: 0.5rem;
  transition: transform 0.2s;
  line-height: 1;
}

.open .arrow {
  transform: rotate(180deg);
}
</style>