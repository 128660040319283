<template>
  <bt-modal :visible="visible" @close="close" :title="modalTitle">
    <template v-slot:body>
      <el-form ref="form" :model="vendorData" label-width="120px" :rules="rules" label-position="top">
        <el-form-item :label="$t('vendorName')" prop="name">
          <el-input v-model="vendorData.name"></el-input>
        </el-form-item>

        <div class="inline-form-section">
          <!-- Latitude -->
          <el-form-item :label="$t('latitude')" prop="lat">
            <el-input v-model.number="vendorData.lat"></el-input>
          </el-form-item>

          <!-- Longitude -->
          <el-form-item :label="$t('longitude')" prop="lng">
            <el-input v-model.number="vendorData.lng"></el-input>
          </el-form-item>

          <el-form-item :label="$t('active')">
          <el-switch v-model="vendorData.active"/>
        </el-form-item>
        </div>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">{{ $t('save') }}</el-button>
      <el-button @click="close">{{ $t('close') }}</el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal'
import { Form, FormItem, Input, Button, Switch, MessageBox } from 'element-ui';

export default {
  name: 'VendorModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-button': Button,
    'el-switch': Switch
  },
  computed: {
    modalTitle() {
      // Returns a title for the modal
      if (this.vendorData && this.vendorData.name) {
        // Use the vendor name if one is available
        return this.vendorData.name;
      } else {
        // Otherwise return 'Unnamed F&B vendor' if we're editing
        return this.$t('unnamedVendor');
      }
    }
  },
  data() {
    return {
      vendorData: null, // Will hold the data we're currently editing
      dataCopied: false, // Indicates data has been copied into local state
      unsavedChanges : false, // Are there unsaved changes?
      rules: {
        name: [
          { required: true, message: this.$t('EnterAName'), trigger: 'blur' },
        ],
        lat: [
          { type: 'number', min: -90, max: 90, message: this.$t('latitudeError'), trigger: 'change' }
        ],
        lng: [
          { type: 'number', min: -180, max: 180, message: this.$t('longitudeError'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    close() {
      if (!this.unsavedChanges) {
        this.$emit('close');
      } else {
        return MessageBox.confirm(
          this.$t('confirmClose'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('close');
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      }
    },
    save() {
      if (this.unsavedChanges) {
        return MessageBox.confirm(
          this.$t('confirmSaveChanges'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('save', this.editing, this.vendorData);
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      } else {
        this.$emit('close');
      }
    }
  },
  props: {
    // When editing a vendor the vendor property is used to pass the details into
    // this component. This data will be copied to this.vendorData to avoid
    // mutating the property.
    vendor: Object,
    visible:{
      // Is the modal currently open?
      type: Boolean,
      default: false
    },
    editing: {
      // Are we editing an existing vendor or creating a new one?
      type: Boolean,
      default: false
    }
  },
  watch: {
    vendorData: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;

      if (this.visible) {
        // When the modal is opened reset the data
        // Check this.editing
        var data = this.vendor ? this.vendor : {};

        this.vendorData = Object.assign({
          name: undefined,
          shortName: undefined,
          lat: undefined,
          lng: undefined,
          active: undefined,
          blipId: undefined,
          attractionsIoId: undefined
        }, data);
      } else {
        // Othewise reset the data
        this.vendorData = null;
      }

      this.unsavedChanges = false;
    },
  }
}
</script>

<style lang="less" scoped>
.inline-form-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  grid-column-gap: 1em;
}
</style>