<template>
  <bt-modal :visible="visible" @close=close :title="modalTitle">
    <template v-slot:body>
      <el-form ref="form" :model="showData" label-width="120px" :rules="rules" label-position="top">
        <el-form-item :label="$t('showName')" prop="name">
          <el-input v-model="showData.name"></el-input>
        </el-form-item>

        <el-form-item :label="$t('image')">
          <file-select
            v-model="fileObj"
            accept=".jpg,.png,.svg"
          />
        </el-form-item>

        <el-form-item :label="$t('active')">
          <el-switch v-model="showData.active"/>
        </el-form-item>

        <el-form-item :label="$t('showType')" v-if="showTypes && showTypes.length">
          <el-select v-model="showData.type" :placeholder="$t('selectAShowType')" value-key="id">
            <el-option
              v-for="(type, idx) in showTypes"
              :key="idx"
              :label="type.name"
              :value="type">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('showVenue')" v-if="showVenues && showVenues.length">
          <el-select v-model="showData.venue" :placeholder="$t('selectAShowVenue')" value-key="id">
            <el-option
              v-for="(venue, idx) in showVenues"
              :key="idx"
              :label="venue.name"
              :value="venue">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">{{ $t('save') }}</el-button>
      <el-button @click="close">{{ $t('close') }}</el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal'
import { Form, FormItem, Input, Button, Switch, MessageBox, Select, Option } from 'element-ui';
import FileSelect from '../FileSelect';

export default {
  name: 'showModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-button': Button,
    'el-switch': Switch,
    'el-select': Select,
    'el-option': Option,
    'file-select': FileSelect
  },
  computed: {
    fileObj: {
      get() {
        if(this.showData && this.showData.image && this.showData.image instanceof File) {
          // Return the assigned image if we have an image and not a URL
          return this.showData.image;
        } else {
          return null;
        }
      },
      set(newValue) {
        this.showData.image = newValue;
      }
    },
    modalTitle() {
      // Returns a title for the modal
      if (this.showData && this.showData.name) {
        // Use the show name if one is available
        return this.showData.name
      } else {
        // Otherwise return 'Unnamed show' if we're editing
        return this.$t('unnamedShow')
      }
    }
  },
  data() {
    return {
      showData: null, // Will hold the data we're currently editing
      dataCopied: false, // Indicates data has been copied into local state
      unsavedChanges : false, // Are there unsaved changes?
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter a show name',
            trigger: 'blur',
          },
        ],
        lat: [
          { type: 'number', min: -90, max: 90, message: this.$t('latitudeError'), trigger: 'change' }
        ],
        lng: [
          { type: 'number', min: -180, max: 180, message: this.$t('longitudeError'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    close() {
      if (!this.unsavedChanges) {
        this.$emit('close');
      } else {
        return MessageBox.confirm(
          this.$t('confirmClose'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('close');
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      }
    },
    save() {
      if (this.unsavedChanges) {
        return MessageBox.confirm(
          this.$t('confirmSaveChanges'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('save', this.editing, this.showData);
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      } else {
        this.$emit('close');
      }
    }
  },
  props: {
    // When editing a ride the ride property is used to pass the details into
    // this component. This data will be copied to this.showData to avoid
    // mutating the property.
    ride: Object,
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    showTypes: Array,
    showVenues: Array,
    editing: {
      // Are we editing an existing ride or creating a new one?
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showData: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;

      if (this.visible) {
        // When the modal is opened reset the data
        // Check this.editing
        var data = this.ride ? this.ride : {}

        this.showData = Object.assign({
          name: undefined,
          legalText: undefined,
          type: undefined,
          venue: undefined,
          active: undefined,
          image: undefined
        }, data);
      } else {
        // Othewise reset the data
        this.showData = null
      }

      this.unsavedChanges = false;
    },
  },
}
</script>

<style lang="less" scoped>
.form-col-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.inline-form-section {
  /*display: flex;
  justify-content: space-between;

  .el-form-item {
    margin-right: 1em;
  }*/
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  grid-column-gap: 1em;
}
</style>
