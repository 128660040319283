import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import router from '../router'
import firebase from 'firebase/app'

Vue.use(Vuex)

function getDefaultState() {
  return {
    init: false, // has the initial load  completed?
    loading: 0, // Count of pending requests
    selectedSiteId: null, // ID of the currently selected site
    loggedIn: false, // Is the user currently logged in?
    me: null // Description of the current user
  }
}

const state = getDefaultState()

export default new Vuex.Store({
  state: state,
  mutations: {
    startLoading(state) {
      state.loading += 1
    },
    stopLoading(state) {
      if (state.loading > 0) state.loading -= 1
    },
    setMe(state, payload) {
      state.me = payload
    },
    setSelectedSiteId(state, id) {
      state.selectedSiteId = id
    },
    setInit(state, value) {
      state.init = value
    },
    setLoggedIn(state, value) {
      state.loggedIn = value
    },
    reset(state) {
      // Reset state to default. NOTE: We need to merge the reset values into
      // the current state. Replacing the whole object would break any exsiting
      // observers
      Object.assign(state, getDefaultState())
      state.init = true // App will still be booted
    }
  },
  actions: {
    async login({ state, commit, dispatch }, { email, idp }) {
      var provider

      if (idp) {
        provider = 'saml.' + idp
      } else if (email.includes('beavertrison.com') || email.includes('trison.uk') || email.includes('beaver-group.com') || email.includes('beaver.group')) {
        provider = 'saml.beaver.trison'
      } else if (email.includes('onmicrosoft.com')) {
        provider = 'saml.local.test'
      } else {
        provider = 'saml.merlin'
      }

      try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        const providerObj = new firebase.auth.SAMLAuthProvider(provider)

        await firebase.auth().signInWithPopup(providerObj)

        // get the user details from the API
        await dispatch('getMe')

        var sites = state.me.sites

        if (sites) {
          // If we have a last site saved (the last site the user was on) go
          // there, otherwise load the first site on the list
          let siteId = localStorage.getItem('lastSite') || sites[0].id

          commit('setSelectedSiteId', siteId)
          router.push({ name: 'dashboard', params: { siteId: siteId } }).catch(() => {})
          commit('setLoggedIn', true)
          await api.login(state.me) // Post the user details to the login endpoint
        } // TODO: Create error*/
      } catch (err) {
        if (err.code && err.code === 'auth/popup-closed-by-user') {
          // The user closed the login popup. Do nothing
          return
        }
      }
    },
    async init({ dispatch, commit, state }) {
      // This action is fired when the page first loads. It checks if a user is
      // currently logged in, and if so it fetches the user data.
      if (firebase.auth().currentUser) {
        // User is already logged in. Get the user data from the API
        try {
          await dispatch('getMe')

          var sites = state.me.sites

          if (sites) {
            let siteId = localStorage.getItem('lastSite') || sites[0].id

            // Set the selectedSiteId
            commit('setSelectedSiteId', siteId)
          }
          commit('setLoggedIn', true)
        } catch (err) {
          // Error reloading data
        }
      }
    },
    async loadSite({ commit }, siteId) {
      // Change the selected site ID and upate the router
      commit('setSelectedSiteId', siteId)
      localStorage.setItem('lastSite', siteId) // Store the selected site in localstorage
      router.push({ name: router.currentRoute.name, params: { siteId } }).catch(() => {})
    },
    async getMe({ commit }) {
      var userDetails = await api.getMe()
      commit('setMe', userDetails)
    },
    async logout({ commit }) {
      // Reset the state and return the user to the login screen
      try {
        commit('reset')
        await firebase.auth().signOut()
        router.push({ name: 'login' }).catch(() => {})
      } catch (err) {
        // Error signaing out (should never happen)
      }
    }
  },
  getters: {
    currentSite(state, getters) {
      if (!getters.availableSites || !state.selectedSiteId) return null
      return getters.availableSites.find(site => site.id === state.selectedSiteId)
    },
    aioSite(state, getters) {
      // Return a boolean indicating if the current site is using atttractions.io
      if (getters.currentSite && getters.currentSite.attractionsIoEnabled && getters.currentSite.attractionsIoEnabled) {
        return true
      } else {
        return false
      }
    },
    blipSite(state, getters) {
      // Return a boolean indicating if the curent site is using Blip
      if (getters.currentSite && getters.currentSite.blipEnabled && getters.currentSite.blipEndpoint) {
        return true
      } else {
        return false
      }
    },
    availableSites(state) {
      if (!state.me || !state.me.sites) return null
      return state.me.sites
    },
    hasAnyPermission(state) {
      // We're using a method-style getter to let us check the user permissions
      // before conditionally rendering a component. For example if a component
      // should only be visible to users with the permission 'write:ride' we
      // would use mapGetters to bring this into the component, then add
      // v-if="hasPermission('write:ride')" to the component in question.
      // PLEASE NOTE: requestedPermissions may be a string or an array of
      // strings, in which case the function will return true if any of them
      // match. This function will always return true if the user has the
      // 'admin' permission
      return requestedPermissions => {
        if (!state.me || !state.me.userType || !state.me.userType.permissions) return false

        // Normalize requestedPermissions to an array
        if (!Array.isArray(requestedPermissions)) requestedPermissions = [requestedPermissions]

        // Iterate through the user's permissions and return true if any of them
        // match. Note that only one requestedPermission has to match
        for (var i = 0, len = state.me.userType.permissions.length; i < len; i++) {
          let permission = state.me.userType.permissions[i]

          if (permission === 'admin' || requestedPermissions.indexOf(permission) !== -1) {
            return true
          }
        }

        // No match found, return false
        return false
      }
    }
  }
})
