<template>
  <div class="site-multiselect">
    <el-select
      multiple
      collapse-tags
      v-if="availableSites"
      :value="value"
      value-key="id"
      @input="
        $emit(
          'input',
          $event.map((site) => ({ id: site.id }))
        )
      "
      placeholder="Select Sites"
      required
    >
      <el-option
        v-for="site in availableSites"
        :key="site.id"
        :value="site"
        :label="site.name"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Select, Option } from 'element-ui';

export default {
  name: 'SiteSelect',
  components: {
    'el-select': Select,
    'el-option': Option,
  },
  computed: {
    ...mapGetters(['availableSites']),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
};
</script>
