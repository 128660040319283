<template>
  <div class="site opening-schedules view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#clock"></use>
      </svg>
      <span>
        {{ $t('openingHours') }}
      </span>
    </h1>

    <div class="page">
      <header>
        <!-- button to add new schedule -->
        <el-button type="primary" @click="addNewSchedule" round icon="si-plus">
          {{ $t('createNewSchedule') }}
        </el-button>
      </header>

      <main class="container">
        <div class="panel">
          <h2>{{ $t('selectedSchedule') }}</h2>

          <!--  first is the selected schedule data -->
          <div
            class="schedule-panel"
            :class="{ 'selected-schedule-container': selectedScheduleId === viewedScheduleId }"
            v-if="selectedSchedule"
            @click="viewSchedule(selectedSchedule.id)"
          >
            <h3>{{ selectedSchedule.name }}</h3>
          </div>

          <!-- unselected schedules container start if any unselected schedules -->
          <h2>{{ $t('availableSchedules') }}</h2>

          <div
            v-for="unSelectedSchedule in unSelectedSchedules"
            :key="unSelectedSchedule.id"
            class="schedule-panel"
            :class="{ 'selected-schedule-container': unSelectedSchedule.id === viewedScheduleId }"
            @click="viewSchedule(unSelectedSchedule.id)"
          >
            <h3>{{ unSelectedSchedule.name }}</h3>
          </div>
        </div>

        <div class="panel grid-item" :class="{ 'manual-override': viewingOverride }">
          <!-- include scheduling-component here -->
          <schedule-view
            :schedule="viewedSchedule"
            :isSelected="viewedSchedule && selectedScheduleId === viewedSchedule.id"
            :key="viewedSchedule ? viewedSchedule.id : null"
            @select="setLive"
            @override="createOverride"
            @removeOverride="removeOverride"
            @edit="editSchedule"
            @delete="deleteSchedule"
          />
        </div>
      </main>
    </div>

    <schedule-modal
      :schedule="editedSchedule"
      :visible="modalAddScheduleOpen"
      :editing="editing"
      :override="isOverride"
      @close="closeModal"
      @save="saveSchedule"
      @saveOverride="saveOverride"
    />
  </div>
</template>

<script>
import api from '../api'
import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '../mixins/Loading'
import { Message, MessageBox, Button } from 'element-ui'
import ScheduleModal from '../components/schedules/ScheduleModal';
import ScheduleView from '../components/schedules/ScheduleView';

export default {
  name: 'OpeningSchedules',
  components: {
    'schedule-modal': ScheduleModal,
    'schedule-view': ScheduleView,
    'el-button': Button
  },
  created() {
    return this.loadSchedules().then(() => {
      if (this.selectedScheduleId) {
        this.viewedScheduleId = this.selectedScheduleId;
      }
    })
  },
  data() {
    return {
      schedules: null,
      schedule: null,
      scheduleToEdit: null,
      isOverride: false, // Are we currently creating an oveerride
      editedScheduleId: null, // ID of the schedule currently being edited
      viewedScheduleId: null, // ID of the schedule currently being viewed
      modalAddScheduleOpen: false,
      editing: false, // Is the user currently editing a schedule?
      modalDeleteScheduleOpen: false,
      newSchedule: null,
    }
  },

  computed: {
    ...mapState(['selectedSiteId']),
    ...mapGetters(['currentSite']),
    editedSchedule() {
      if (!this.schedules || !this.editedScheduleId) return null;
      return this.schedules.find(
        schedule => schedule.id === this.editedScheduleId
      );
    },
    viewingOverride() {
      // Is the currently viewed schedule an override?
      return this.viewedSchedule && this.viewedSchedule.override;
    },
    viewedSchedule() {
      if (!this.schedules || !this.viewedScheduleId) return null;
      return this.schedules.find(
        schedule => schedule.id === this.viewedScheduleId
      );
    },
    selectedScheduleId() {
      if (this.currentSite.schedule) {
        return this.currentSite.schedule.id
          ? this.currentSite.schedule.id
          : null
      } else return null
    },
    selectedSchedule() {
      if (!this.schedules || !this.selectedScheduleId) return null //TODO this isn't nice, do some scenario for what happens if none is selected or selectScheduleId is null
      return this.schedules.find(
        (schedule) => schedule.id === this.selectedScheduleId
      )
    },
    unSelectedSchedules() {
      if (!this.schedules) return null
      return this.schedules.filter(
        (schedule) => schedule.id !== this.selectedScheduleId
      ) //TODO make sure not to throw error if nothing is returned here, eg. only one schedule is set and is selected.
    },
    isManualOverride() {
      if (
        !this.selectedSchedule ||
        !this.selectedSchedule.override ||
        !this.selectedSchedule.override.id
      )
        return false
      return true
    },
    isManuallyOverridable() {
      // this shows the scheduling component if they have to show the
      // set as manual override button
      // if the manual override is live then don't show this button
      return this.isManualOverride ? false : true
    },
    //check name for duplicate
    names() {
      if (!this.schedules) return []

      return this.schedules.map((schedule) => schedule.name)
    },
  },
  methods: {
    ...mapActions(['getMe']),
    closeModal() {
      this.editing = false;
      this.isOverride = false;
      this.modalAddScheduleOpen = false;
    },
    createOverride(scheduleId) {
      var schedule = this.schedules.find(schedule => schedule.id === scheduleId);

      if (!schedule) return;

      this.editing = true;
      this.isOverride = true;
      this.editedScheduleId = scheduleId;
      this.modalAddScheduleOpen = true;
    },
    editSchedule(id) {
      // Open the edit schedule modal
      console.log('Edit schedule', id);
      this.editing = true;
      this.editedScheduleId = id;
      this.modalAddScheduleOpen = true;
    },
    deleteSchedule(id) {
      // Delete the a schedule

      // 1. Get user confirmation before deletion. NOTE: This will display a
      // different message if the user attempts to delete the current live schedule
      var message;
      var deletingLiveSchedule = id === this.selectedScheduleId;

      if (deletingLiveSchedule) {
        message = this.$t('scheduleInUse');
      } else {
        message = this.$t('confirmScheduleDelete');
      }

      // 1. Confirm deletion
      return MessageBox.confirm(
        message,
        this.$t('warning'),
        {
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
        }
      ).then(() => {
        // 2. Attempt to delete the schedule
        this.startLoading();
        return api.deleteSchedule(this.selectedSiteId, id);
      }).then(() => {
        return this.loadSchedules();
      }).then(() => {
        this.stopLoading();
      }).catch(() => {
        this.stopLoading();
      })
    },
    viewSchedule(id) {
      this.viewedScheduleId = id;
    },
    loadSchedules() {
      this.startLoading()
      return api
        .getSchedules(this.selectedSiteId, { include: ['override'] })
        .then((response) => {
          this.schedules = response.data;
          this.stopLoading();
        })
        .catch(() => {
          this.stopLoading();
          Message({
            message: this.$t('errorLoadingSchedules'),
            type: 'error'
          });
        })
    },
    addNewSchedule() {
      this.modalAddScheduleOpen = true
    },
    saveOverride(scheduleData) {
      this.modalAddScheduleOpen = false;

      // Seperate the ID (if present) from the rest of the data
      var { id, ...schedule } = scheduleData;

      // Add an override to the schedule data
      schedule.name = this.$t('manualOverride');
      schedule.override = {
        id: id
      }

      // Create the schedule
      this.startLoading();
      return api.createSchedule(this.selectedSiteId, schedule).then(newSchedule => {
        // Display the new schedule
        this.viewedScheduleId = newSchedule.id;

        // Get the ID of the newly created schedule and assign it to the site
        return api.updateSite(this.selectedSiteId, {
          schedule: {
            id: newSchedule.id
          }
        });
      }).then(() => {
        Message({
          message: 'Created override schedule',
          type: 'success'
        });

        return this.loadSchedules();
      }).then(() => {
        return this.getMe();
      }).catch(() => {
        Message({
          message: this.$t('errorSavingSchedule'),
          type: 'error'
        });
      }).finally(() => {
        console.log('Finally override');
        this.editing = false;
        this.stopLoading();
      });
    },
    saveSchedule(isEdit, scheduleData) {
      // Create, or save changes to a schedule
      this.modalAddScheduleOpen = false;

      // Seperate the ID (if present) from the rest of the data
      var { id, ...schedule } = scheduleData;

      var request;

      // Create an update or create request depending on if we are editing an
      // exisiting ride (editing is true)
      if (isEdit) {
        request = api.updateSchedule(this.selectedSiteId, id, schedule);
      } else {
        request = api.createSchedule(this.selectedSiteId, schedule);
      }

      this.startLoading();

      return request.then(() => {
        var message;

        if (isEdit) {
          message = this.$t('savedChangesToSchedule', { scheduleName: schedule.name });
        } else {
          message = this.$t('createdSchedule', { scheduleName: schedule.name });
        }

        Message({
          message: message,
          type: 'success'
        });

        return this.loadSchedules();
      }).catch(() => {
        Message({
          message: this.$t('errorSavingSchedule'),
          type: 'error'
        });
      }).finally(() => {
        console.log('Finally save');
        this.editing = false;
        this.stopLoading();
      });
    },
    setLive(scheduleId) {
      //if we click this button we need to trigger an api update on sites to set this site schedule as live
      if (scheduleId) {
        var data = {
          schedule: {
            id: scheduleId
          },
        }

        var schedule = this.schedules.find(schedule => schedule.id === scheduleId) || {};

        // open confirm messagebox
        return MessageBox.confirm(
          this.$t('changeLiveScheduleConfirmMessage', { scheduleName: schedule.name }),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning',
          }
        ).then(() => {
          // If the current schedule is an override we will need to delete this
          if (
            this.selectedSchedule &&
            this.selectedSchedule.override
          ) {
            // Select the original schedule and delete the override
            return api.deleteSchedule(this.selectedSiteId, this.selectedSchedule.id);
          } else {
            return;
          }
        }).then(() => {
          //if accepted, send api request
          //if success, reload page with this schedule selected as live
          return api.updateSite(this.selectedSiteId, data);
        }).then(() => {
          //need a function to reload site with the schedule id, that will be discussed in the afternoon
          // return this.loadUpdatedSite(this.selectedSiteId)
          Message({
            message: this.$t('editCompleted'),
            type: 'success',
          });

          // Reload the user site access
          return Promise.all([
            this.getMe(),
            this.loadSchedules()
          ]);
        }).catch((err) => {
          console.log(err);
          // err === 'cancel' indicates the user canceled the action
          if (err !== 'cancel') {
            Message.error(this.$t('errorSelectingSchedule'))
          }
        });
      } else {
        //this is not really possible since the trigger button is the unSelectedSchedule.
        Message.error(this.$t('noScheduleIdSentPleaseContactSupport'))
        return
      }
    },
    removeOverride(overrideId) {
      // Find the override schedule
      var schedule = this.schedules.find(schedule => schedule.id === overrideId);

      if (!schedule) {
        // It's possible that the original schedule has been deleted. If this is
        // the case we will need to select something else
        return;
      }

      // TODO: Check for no override
      if (!schedule.override) {
        //console.log('No override');
      }

      return MessageBox.confirm(
        this.$t('confirmRemoveOverride'),
        this.$t('warning'),
        {
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
        }
      ).then(() => {
        return api.updateSite(this.selectedSiteId, {
          schedule: {
            id: schedule.override.id
          },
        })
      }).then(() => {
        this.viewedScheduleId = schedule.override.id;
        // Delete the override
        return api.deleteSchedule(this.selectedSiteId, overrideId);
      }).then(() => {
        Message({
          message: 'Removed override',
          type: 'success'
        });

        return Promise.all([
          this.getMe(),
          this.loadSchedules()
        ]);
      }).catch(err => {
        // Display error (Unless the user canceled)
        if (err !== 'cancel') {
          Message({
            message: this.$t('errorDeletingSchedule'),
            type: 'error'
          });
        }
      });
    }
  },
  mixins: [Loading],
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.border() {
  border-radius: 25px;
  padding: 1vh 0 2.5vh 0;
}
.textCapitalCentered() {
  text-transform: uppercase;
  text-align: center;
}

.container {
  //border: 1px solid #e6e6e6;
  max-width: 100%;
  //background: white;
  //padding: 2vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  // grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  font-size: 13px;
}

.selected-schedule-container {
  background: @primaryblue;
  color: white;
  border: 1px solid red;
}

.selected-schedule {
  display: grid;
  grid-gap: 0.1vw;
  background: white;
  grid-template-columns: repeat(auto-fit, minmax(2vw, 1fr));
}
.calendar-days {
  background: @primaryblue;
}
.unselected-schedule-container {
  .textCapitalCentered();
}

.el-button + .el-button {
  margin: 0;
}

.schedule-panel {
  padding: 1.25rem 1.5rem;
  border-radius: 1rem;
  letter-spacing: 1px;
  .textCapitalCentered();
  width: 100%;
  cursor: pointer;
  border: 1px solid #ddd;
  margin-bottom: 1rem;

  h3 {
    margin: 0;
  }
}

.manual-override {
  border: 0.5rem solid @red;
  padding: 0.5rem 1rem; // Adjsut the padding to allow for the border
}

header {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 780px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, auto);
  }
}
</style>
