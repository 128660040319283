import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import i18n from './i18n'
import firebase from 'firebase/app';
import { liveConfig, devConfig } from './config';
import 'firebase/auth';
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: 'G-515Y7LH60Y' }
});

var app;

// initialise
var config = process.env.NODE_ENV === 'production' ? liveConfig : devConfig;
firebase.initializeApp(config);
firebase.auth().onAuthStateChanged(function() {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app');
  }
});
