import Sdk from '../lib/attractionsSdk'

// Create and export an instance of the API client
/*var api = new Sdk(
  'http://localhost:3000/v1',
  { token: 'vwAx06rXsQymzCUuBpuohrCw7BpBuKDK8bXQueybjwJdyHY1r-GOeFVDvI92ohXf' }
);*/

/* using localhost */
/*var api = new Sdk(
  'http://localhost:3000/v1'
);*/

/* or using fieebase authentication */
/*var api = new Sdk(
  'https://dev-api.attractions.ninja/v1'
);*/

// Use the environment variables to work our which API to connect to
var endpoint;

if (process.env.VUE_APP_SDK_API) {
  // If we have VUE_APP_SDK_API is set use that. You can use this if you want to
  // connect to a local instance of the API (See .env.example)
  endpoint = process.env.VUE_APP_SDK_API;
} else if (process.env.NODE_ENV === 'production') {
  // If we're running in production use the production endpoint
  endpoint = 'https://api.attractions.ninja/v1';
} else {
  // Otherwise use the development endpoint
  endpoint = 'https://dev-api.attractions.ninja/v1';
}

// Uncomment for local testing.
//endpoint = 'http://localhost:3000/v1'

//use env file:
var api = new Sdk(
  endpoint
);
export default api
