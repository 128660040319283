<template>
  <bt-modal :visible="visible" @close=close :title="$t('showTypes')">
    <template v-slot:body>
      <p v-if="!showTypes || !showTypes.length" class="empty">
        {{ $t('noShowTypes') }}
      </p>
      <ul v-else>
        <li v-for="(type, idx) in showTypes" :key="idx">
          <el-input v-model="type.name"></el-input>
          <el-button class="delete-button" type="danger" @click="deleteShowType(idx)">
            {{ $t('delete') }}
          </el-button>
        </li>
      </ul>

      <el-button @click="addShowType">{{ $t('addShowType') }}</el-button>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">
        {{ $t('save') }}
      </el-button>

      <el-button @click="close">
        {{ $t('close') }}
      </el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal';
import { MessageBox, Input , Button } from 'element-ui'

export default {
  name: 'ShowTypesModal',
  components: {
    'bt-modal': BtModal,
    'el-input': Input,
    'el-button': Button
  },
  data() {
    return {
      // An array of show types deleted by the user. This will be emitted
      // to the parent on save.
      toDelete: [],

      // Has the data been copied into local state?
      dataCopied: false,

      // Available status messages (Will be copied from the this.messages prop)
      showTypes: [],

      // Are there any unsaved changes in this modal?
      unsavedChanges: false,
    }
  },
  methods: {
    save() {
      // Send the updated messages to the parent for saving

      // 1. Get confimration
      return MessageBox.confirm(
          this.$t('confirmStatusUpdate'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('save', this.showTypes, this.toDelete);
        }).catch(() => {
          // User cancelled
        });
    },
    deleteShowType(idx) {
      var deleted = this.showTypes.splice(idx, 1);
      this.toDelete.push(deleted[0]);
    },
    addShowType() {
      // Add a new message
      this.showTypes.push({
        name: '',
        active: true
      });
    },
    close() {
      if (!this.unsavedChanges) {
        this.$emit('close');
      } else {
        return MessageBox.confirm(
          this.$t('confirmClose'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('close');
          //this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      }
    },
  },
  props: {
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    types: {
      // Available show types
      type: Array
    }
  },
  watch: {
    showTypes: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;

      if (this.visible) {
        // When the modal is opened reset the data
        var data = this.types || [];
        this.showTypes = JSON.parse(JSON.stringify(data));
      } else {
        // Othewise reset the data
        this.showTypes = [];
      }

      this.toDelete = []; // Reset deleted message array
      this.unsavedChanges = false; // Reset unsaved changes status
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  margin-bottom: 0.5rem;
}

.delete-button {
  margin-left: 0.5rem
}

.empty {
  font-style: italic;
}
</style>