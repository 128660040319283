<template>
  <button class="icon-button" @click="$emit('click')">
    <slot>
      <i :class="icon"></i>
    </slot>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      default: 'si-ninja-star'
    }
  }
}
</script>

<style lang="less" scoped>
button {
  background: none;
  border: none;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.5rem;
  height: 3rem;
  width: 3rem;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }

  &:focus, &:active {
    border: 2px solid #409eff;
    outline: 0;
  }
}
</style>