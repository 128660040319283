<template>
  <div class="menu-wrapper" :class="{ 'hidden': !menuVisible && !closing }">
    <div
      class="menu-backdrop"
      :style="{ 'opacity': this.opacity }"
      @click="$emit('close')"
    ></div>
    <div
      class="bt-menu"
      ref="menuWrapper"
      :style="{ 'transform': menuOffset }"
      @transitionend.self="clearTransition"
    >
      <header class="menu-header">
        <slot name="header">
        </slot>
      </header>

      <nav :aria-label="label">
        <ul>
          <slot></slot>
        </ul>
      </nav>

      <footer class="menu-footer">
        <slot name="footer">
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtMenu',
  data() {
    return {
      menuOffset: null, // Controls the positioning of the menu during transitions
      opacity: null, // Controls the opacity of the background
      closing: false // The menu is currently clsoing (mobile only)
    }
  },
  props: {
    label: String, // Menu's aria-label
    menuVisible: Boolean, // On smaller devices this indicates if the menu is currently visible
  },
  methods: {
    clearTransition() {
      if (this.closing) {
        this.menuVisible = false;
      }
      this.closing = false;
      this.menuOffset = null;
      this.opacity = null;
    }
  },
  watch: {
    menuVisible() {
      // Toggle the menu visibility
      if (!this.menuVisible) {
        console.log('closing');
        // Close the menu
        this.closing = true;
        this.menuOffset = 'translateX(0)';
        this.opacity = 1;
        window.setTimeout(() => {
          this.opacity = 0;
          this.menuOffset = 'translateX(-100%)';
        }, 0);
      } else {
        // Open the menu
        console.log('opening menu');
        this.menuOffset = 'translateX(-100%)';
        this.opacity = 0;
        window.setTimeout(() => {
          this.opacity = 1;
          this.menuOffset = 'translateX(0)';
        }, 0);
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.menu-header {
  padding: 1.5em 0 0.75em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bt-menu {
  background-color: @menu-background;
  overflow-y: auto;
  height: 100%;
  z-index: 100;
  width: 15rem;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

nav {
  flex-grow: 1;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 1rem;
  border-left: .25rem solid transparent;
}

// Collapse the menu on smaller screens
@media only screen and (max-width: 950px) {
  .bt-menu {
    position: absolute;
    transition: transform 0.3s ease;
  }

  .hidden {
    display: none;
  }

  .menu-backdrop {
    background: #0000007a;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 95;
    transition: opacity 0.3s ease;
  }
}
</style>