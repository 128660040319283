// Firebase production config
var liveConfig = {
  apiKey: "AIzaSyAc_oslLyQTy-rUR3oJx_8LVQ-ghEEV49Y",
  authDomain: "attractions-ninja-live.firebaseapp.com",
  projectId: "attractions-ninja-live",
  storageBucket: "attractions-ninja-live.appspot.com",
  messagingSenderId: "1013896734159",
  appId: "1:1013896734159:web:32afeef771995442b81eba",
  measurementId: "G-515Y7LH60Y"
};

// Firebase development config
var devConfig = {
  apiKey: "AIzaSyB4bnSRGAwyF9bI420sTmFiRWsHWMwPrbI",
  authDomain: "attractions-ninja-dev.firebaseapp.com",
  projectId: "attractions-ninja-dev",
  storageBucket: "attractions-ninja-dev.appspot.com",
  messagingSenderId: "483259203749",
  appId: "1:483259203749:web:05469a7fd5f58e346bcd4c",
  measurementId: "G-5Y4ZH69CNV"
}

export { liveConfig, devConfig };