var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"site dashboard view"},[_c('h1',{staticClass:"page-heading"},[_vm._v(_vm._s(_vm.$t('dashboard')))]),_c('main',{staticClass:"dashboard"},[_c('div',{staticClass:"status"},[(_vm.loaded)?[(
            _vm.hasAnyPermission(['read:ride', 'write:ride', 'delete:ride']) &&
            _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.rides
          )?_c('div',{staticClass:"panel rides"},[_c('div',{staticClass:"flex space-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t('rides')))]),_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#ride"}})])]),_c('div',{staticClass:"flex space-between"},[_c('div',{staticStyle:{"flex":"1 0 50%"}},[_c('h3',[_c('span',{staticClass:"circle teal"},[_vm._v(_vm._s(_vm.rideInfo.open))]),_vm._v(" "+_vm._s(_vm.$t('open'))+" ")]),_c('h3',[_c('span',{staticClass:"circle rose"},[_vm._v(_vm._s(_vm.rideInfo.closed))]),_vm._v(" "+_vm._s(_vm.$t('closed'))+" ")])]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"barOverflow"},[_c('div',{staticClass:"bar",style:({ transform: 'rotate(' + _vm.getOpenDeg + 'deg)' })})])])]),_c('div',{staticClass:"panel-footer"},[_c('el-button',{staticClass:"expand",attrs:{"round":""}},[_c('router-link',{attrs:{"to":{ name: 'rides', params: { siteId: this.selectedSiteId } }}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" "),_c('i',{staticClass:"si-arrow-right"})])],1)],1)]):_vm._e(),(
            _vm.hasAnyPermission(['read:show', 'write:show', 'delete:show']) &&
            _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.shows
          )?_c('div',{staticClass:"panel show"},[_c('div',{staticClass:"flex space-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t('shows')))]),_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#show"}})])]),_c('div',{staticClass:"flex space-between"},[_c('div',[_c('h3',[_c('span',{staticClass:"circle white"},[_vm._v(_vm._s(_vm.showInfo.active))]),_vm._v(" "+_vm._s(_vm.$t('active'))+" ")]),_c('h3',[_c('span',{staticClass:"circle black"},[_vm._v(_vm._s(_vm.showInfo.inactive))]),_vm._v(" "+_vm._s(_vm.$t('inactive'))+" ")])]),_c('div',{staticClass:"show-graphs"},[_c('div',{staticClass:"active",style:({ height: _vm.activeShows + '%' })}),_c('div',{staticClass:"inactive",style:({ height: 100 - _vm.activeShows + '%' })})])]),_c('div',{staticClass:"panel-footer"},[_c('el-button',{staticClass:"expand",attrs:{"round":""}},[_c('router-link',{attrs:{"to":{ name: 'shows', params: { siteId: this.selectedSiteId } }}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" "),_c('i',{staticClass:"si-arrow-right"})])],1)],1)]):_vm._e(),(
            _vm.hasAnyPermission([
              'read:birthday',
              'write:birthday',
              'delete:birthday' ]) &&
            _vm.currentSite && _vm.currentSite.siteFeatures && _vm.currentSite.siteFeatures.birthdays
          )?_c('div',{staticClass:"panel birthday"},[_c('div',{staticClass:"flex space-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t('birthdays')))]),_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#cake"}})])]),_c('div',{staticClass:"padding-left padding-right birthdays-wrapper"},[_c('div',{staticClass:"birthdays"},[_c('h2',[_vm._v(_vm._s(_vm.birthdays.length))])]),_c('div',{staticClass:"birthday-text"},[_c('h3',[_vm._v(_vm._s(_vm.$tc('birthdaysToday', _vm.birthdays.length)))])])]),_c('div',{staticClass:"panel-footer"},[_c('el-button',{staticClass:"expand",attrs:{"round":""}},[_c('router-link',{attrs:{"to":{
                  name: 'birthdays',
                  params: { siteId: this.selectedSiteId },
                }}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" "),_c('i',{staticClass:"si-arrow-right"})])],1)],1)]):_vm._e(),_vm._m(0),(_vm.hasAnyPermission(['admin']))?_c('div',{staticClass:"panel admin"},[_c('div',{staticClass:"flex space-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t('admin')))]),_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#gear"}})])]),_c('div',{staticClass:"admin-links-container"},[_c('el-button',{staticClass:"admin-links",attrs:{"size":"mini","type":"info","plain":"","shadow":"hover"}},[_c('router-link',{staticClass:"admin-links",attrs:{"to":{
                  name: 'users',
                  params: { siteId: this.selectedSiteId },
                }}},[_vm._v(" Users ")])],1),_c('el-button',{staticClass:"admin-links",attrs:{"size":"mini","type":"info","plain":"","shadow":"hover"}},[_c('router-link',{staticClass:"admin-links",attrs:{"to":{
                  name: 'user types',
                  params: { siteId: this.selectedSiteId },
                }}},[_vm._v(" User Types ")])],1),_c('el-button',{staticClass:"admin-links",attrs:{"size":"mini","plain":"","type":"info","shadow":"hover"}},[_c('router-link',{staticClass:"admin-links",attrs:{"to":{
                  name: 'tokens',
                  params: { siteId: this.selectedSiteId },
                }}},[_vm._v(" Tokens ")])],1),_c('el-button',{staticClass:"admin-links",attrs:{"size":"mini","type":"info","plain":"","shadow":"hover"}},[_c('router-link',{staticClass:"admin-links",attrs:{"to":{
                  name: 'sites',
                  params: { siteId: this.selectedSiteId },
                }}},[_vm._v(" Sites ")])],1)],1)]):_vm._e(),(
            _vm.hasAnyPermission([
              'read:schedule',
              'write:schedule',
              'delete:schedule' ])
          )?_c('div',{staticClass:"panel schedule"},[_c('div',{staticClass:"flex space-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t('openingHours')))]),_c('svg',{staticClass:"icon"},[_c('use',{attrs:{"xlink:href":"#clock"}})])]),(_vm.currentSite && _vm.currentSite.schedule)?[_c('div',{staticClass:"schedule-text"},[_c('h2',{staticClass:"schedule-text-bg"},[_vm._v(" "+_vm._s(_vm.currentSite.schedule.name)+" ")]),_c('h2',{staticStyle:{"margin-block-start":"0 !important"}},[_vm._v(" Active now ")])])]:_vm._e(),_c('div',{staticClass:"panel-footer"},[_c('el-button',{staticClass:"expand",attrs:{"round":""}},[_c('router-link',{attrs:{"to":{ name: 'opening schedules', siteId: this.selectedSiteId }}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" "),_c('i',{staticClass:"si-arrow-right"})])],1)],1)],2):_vm._e()]:_vm._e()],2),(_vm.hasAnyPermission(['read:log_entry']))?_c('div',{staticClass:"panel flex column"},[_c('h2',[_vm._v(_vm._s(_vm.$t('activity30Days')))]),_c('div',{staticClass:"log"},[_c('div',{staticClass:"log-entry-wrapper"},_vm._l((_vm.logEntries),function(entry,idx){return _c('log-entry',{key:idx,attrs:{"entry":entry}})}),1)])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel signage"},[_c('div',[_c('a',{attrs:{"href":"https://my.signage.ninja/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/img/signage-logo.svg")}})])])])}]

export { render, staticRenderFns }