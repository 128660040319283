<template>
  <bt-modal :visible="visible" @close=close :title="$t('setAllRides')">
    <template v-slot:body>
      <el-form label-position="top">
        <el-form-item label="Status message" size="medium">
          <el-select
            v-if="messages && messages.length"
            v-model="formData.statusMessage"
          >
            <el-option
              v-for="message in messages"
              :key="message.id"
              :label="message.value"
              :value="message.value">
            </el-option>
          </el-select>

          <el-input v-model="formData.statusMessage" v-else></el-input>
        </el-form-item>

        <el-form-item :label="$t('queueTimeMins')" size="medium">
          <!--<el-input :value="value.queueTime" @input="set('queueTime', parseInt($event))" :min="0" type="number"/>-->
          <el-input-number v-model="queueTimeMins" :min="0"/>
        </el-form-item>

        <div class="switches">
          <el-form-item label="Open / closed" class="center">
            <el-switch v-model="formData.operational"/>
          </el-form-item>

          <el-form-item label="Blip" class="center" v-if="blipSite">
            <el-switch v-model="formData.useBlip"/>
          </el-form-item>

          <el-form-item label="Custom opening hours"  class="center">
            <el-switch v-model="formData.useCustomOpeningTimes"/>
          </el-form-item>
        </div>

        <el-form-item :label="$t('openingHours')" class="times">
          <el-time-picker
            :disabled="!formData.useCustomOpeningTimes"
            is-range
            v-model="openingTimes"
            range-separator="⁠–⁠"
            :start-placeholder="$t('openingTime')"
            :end-placeholder="$t('closingTime')"
            format="HH:mm"
          />
        </el-form-item>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">{{ $t('setAllRides') }}</el-button>
      <el-button @click="close">{{ $t('close') }}</el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal';
import {
  Form, FormItem, Input, Button, Switch, TimePicker, InputNumber, Select, Option
} from 'element-ui';
import { mapGetters } from 'vuex';

export default {
  name: 'SetAllRidesModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-button': Button,
    'el-switch': Switch,
    'el-time-picker': TimePicker,
    'el-input-number': InputNumber,
    'el-select': Select,
    'el-option': Option
  },
  computed: {
    openingTimes: {
      get() {
        var openingTime, closingTime;

        openingTime = this.toDateObject(this.formData.customOpeningTimes.opens);
        closingTime = this.toDateObject(this.formData.customOpeningTimes.closes);

        return [
          openingTime,
          closingTime
        ];
      },
      set(newValue) {
        this.formData.customOpeningTimes.opens = this.toTimeString(newValue[0]);
        this.formData.customOpeningTimes.closes = this.toTimeString(newValue[1]);
      }
    },
    queueTimeMins: {
      get() {
        return this.formData && this.formData.queueTime ? this.formData.queueTime / 60 : 0;
      },
      set(newValue) {
        this.formData.queueTime = newValue * 60;
      }
    },
    ...mapGetters([
      'blipSite'
    ])
  },
  data() {
    return {
      detectChanges: false, // Detect unsaved changes?
      unsavedChanges: false, // Are there unsaved changes?
      formData: {
        queueTime: 0,
        operational: false,
        useBlip: false,
        statusMessage: undefined,
        useCustomOpeningTimes: false,
        customOpeningTimes: {
          opens: '09:00',
          closes: '18:00'
        }
      }
    }
  },
  methods: {
    save() {
      // Clone the data before manipulating it
      var data = JSON.parse(JSON.stringify(this.formData));

      // Emit a save event back to the parent
      if (!data.useCustomOpeningTimes) {
        // If useCustomOpeningTimes is disabled we shouldn't overwrite any
        // existing opening time
        delete data.customOpeningTimes;
      }

      this.$emit('save', data);
    },
    close() {
      this.$emit('close');
    },
    toDateObject(time) {
      // Convert a time string (hh:mm) to a Date object
      var [hours, mins] = time.split(':');
      var date = new Date();
      date.setHours(hours);
      date.setMinutes(mins);
      return date;
    },
    toTimeString(date) {
      // Convert a Date object to a time string (hh:mm)
      var hours = date.getHours().toString();
      var mins = date.getMinutes().toString();
      if (hours.length === 1) hours = '0' + hours;
      if (mins.length === 1) mins = '0' + mins;
      return hours + ':' + mins;
    }
  },
  props: {
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    messages: Array
  },
  watch: {
    visible() {
      // Reset the data if the visible status changes
      this.detectChanges = false;

      this.formData = {
        queueTime: 0,
        operational: false,
        useBlip: false,
        statusMessage: undefined,
        useCustomOpeningTimes: false,
        customOpeningTimes: {
          opens: '09:00',
          closes: '18:00'
        }
      };
    },
    formData: {
      // Detect unsaved changes
      handler() {
        if (this.detectChanges) {
          this.unsavedChanges = true;
        } else {
          this.detectChanges = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.switches {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.el-input-number {
  // Override number input width
  width: 170px;
}
</style>