<template>
  <div class="show-status-panel">
    <div class="image-wrapper" :class="{ 'empty': !showImage }">
      <img :src="showImage" v-if="showImage">
      <i class="image-placeholder si-image" v-else></i>
    </div>

    <div class="form-wrapper">
      <header>
        <h3>
          <span>{{ name }}</span>
          <el-tooltip v-if="edited" :content="$t('unsavedChanges')" effect="light">
            <i class="si-info-circle"></i>
          </el-tooltip>
        </h3>

        <el-button
          v-if="hasAnyPermission(['write:show', 'delete:show'])"
          @click="$emit('edit', id)"
          size="medium"
          type="primary"
        >
          {{ $t('editShow') }}
        </el-button>
      </header>

      <el-form label-position="top" size="medium">
        <div class="inline-form-section">
          <el-form-item :label="$t('statusMessage')">
            <el-select
              v-if="messages && messages.length"
              :value="value.statusMessage"
              @input="set('statusMessage', $event)"
              clearable
            >
              <el-option
                v-for="message in messages"
                :key="message.id"
                :label="message.value"
                :value="message.value">
              </el-option>
            </el-select>

            <el-input
              :value="value.statusMessage"
              @input="set('statusMessage', $event)"
              v-else
            />
          </el-form-item>

          <el-form-item :label="$t('operational')" class="center">
            <el-switch :value="value.operational" @input="set('operational', $event)"/>
          </el-form-item>
        </div>

        <ol class="time-list">
          <li v-for="(time, idx) in value.times" :key="idx">
            <el-time-picker
              :value="timeObjs[idx]"
              @input="setTime($event, idx)"
              format="HH:mm"
              :clearable="false"
              class="show-time-picker"
            >
            </el-time-picker>
            <el-button type="danger" icon="si-trash" @click="deleteTime(idx)">
            </el-button>
          </li>
        </ol>

        <el-button @click="addTime">{{ $t('addTime') }}</el-button>
      </el-form>
      </div>

  </div>
</template>

<script>
import { Form, FormItem, TimePicker, Switch, Input, Button, Tooltip, Select, Option } from 'element-ui';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';

export default {
  name: 'ShowPanel',
  components: {
    'el-form': Form,
    'el-form-item': FormItem,
    'el-time-picker': TimePicker,
    'el-switch': Switch,
    'el-input': Input,
    'el-button': Button,
    'el-tooltip': Tooltip,
    'el-select': Select,
    'el-option': Option
  },
  computed: {
    timeObjs() {
      if (!this.value || !this.value.times) return [];

      return this.value.times.map(time => {
        var [hours, minutes] = time.split(':');

        return DateTime.fromObject({
          hour: hours,
          minute: minutes,
          second: 0,
          millisecond: 0
        }).toJSDate();
      });
    },
    ...mapGetters([
      'hasAnyPermission'
    ])
  },
  methods: {
    set(key, value) {
      // When any value is updated create and emit a new object.
      return this.$emit('input', { ...this.value, [key]: value }, this.id);
    },
    deleteTime(idx) {
      // Copy the times array
      var newArr = this.value.times.slice(0);
      // Remove the deleted time
      newArr.splice(idx, 1);
      this.set('times', newArr);
    },
    addTime() {
      // Add a show time
      // Copy the times array
      var newArr = this.value.times ? this.value.times.slice(0) : [];

      // Add a new time to the copied array;
      newArr.push('12:00');
      this.set('times', newArr);
    },
    setTime(time, position) {
      // When the user updates a time update the whole array
      // First create a time string for the new time
      var newTime = DateTime.fromJSDate(time).toFormat('HH:mm');
      // Copy this.value.times. Replace the edited entry with the new string
      var newArr = this.value.times.map((time, idx) => idx === position ? newTime : time);
      this.set('times', newArr);
    }
  },
  props: {
    id: String, // Show ID
    name: String, // Show name
    messages: Array,
    edited: Boolean, // Has the status been edited?
    showImage: String, // Show image
    value: Object // Show data
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/vars';

.show-status-panel {
  background: white;
  box-shadow: @panel-shadow;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  display: flex;
}

.image-wrapper {
  flex: 0 1 15rem;
  margin-right: 1rem;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-placeholder {
  font-size: 5rem;
  color: #959595;
}

.form-wrapper {
  flex: 1 1 auto;
}

.inline-form-section {
  /*display: flex;
  justify-content: space-between;
  flex-wrap: wrap;*/
  display: grid;
  grid-template-columns: 25rem 1.5fr auto;
  grid-template-rows: 1fr;
  gap: 0.75em 0;
  grid-template-areas:
    "status switches times";

  .status {
    grid-area: status;
    display: flex;
  }

  .time {
    grid-area: time;
  }

  .switches {
    grid-area: switches;
    display: flex;
    justify-self: end
  }

  .times {
    grid-area: times;
  }
}

.form-spacer {
  flex: 1;
}

.show-status-panel /deep/ .el-form-item__label {
  font-size: 0.75rem; // 12px
  line-height: 1.5;
}

.show-status-panel /deep/ .center {
  text-align: center;
}

// Panel header section
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.el-form-item {
  padding: 0 0.75em;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

// Override date editor width
.show-status-panel /deep/ .el-date-editor {
  width: 250px;
}

.show-status-panel /deep/ input::-webkit-outer-spin-button,
.show-status-panel /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 1350px) {
  .inline-form-section {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "status status"
      "switches times";

    .status {
      justify-self: center;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .inline-form-section {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "status"
      "switches";

    .switches, .times {
      justify-self: center;
    }
  }
}

@media only screen and (max-width: 630px) {
  /*.inline-form-section {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "status"
      "switches";

    .switches, .times {
      justify-self: center;
    }
  }*/

  .show-status-panel {
    flex-direction: column;
  }

  .image-wrapper {
    margin: 0 0 1rem;
  }

  .empty {
    display: none;
  }
}

.time-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.time-list li {
  display: flex;
  margin-bottom: 0.5rem;

  & > .show-time-picker {
    margin-right: 0.5rem;
  }
}

h3 {
  margin: 0;

  span, i {
    vertical-align: middle;
  }

  i {
    color: @orange;
  }
}
</style>