<template>
  <bt-modal :visible="visible" @close=close :title="$t('presetStatusMessages')">
    <template v-slot:body>
      <p v-if="!statusMessages || !statusMessages.length" class="empty">
        {{ $t('noStatusMessages') }}
      </p>
      <ul v-else>
        <li v-for="(message, idx) in statusMessages" :key="message.id">
          <el-input v-model="message.value"></el-input>
          <el-button class="delete-button" type="danger" @click="deleteMessage(idx)">
            {{ $t('delete') }}
          </el-button>
        </li>
      </ul>

      <el-button @click="addMessage">
        {{ $t('addMessage') }}
      </el-button>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">
        {{ $t('save') }}
      </el-button>

      <el-button @click="close">
        {{ $t('close') }}
      </el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from './BtModal';
import { MessageBox, Button, Input } from 'element-ui';

export default {
  name: 'MessageModal',
  components: {
    'bt-modal': BtModal,
    'el-button': Button,
    'el-input': Input
  },
  data() {
    return {
      // An array of status messages deleted by the user. This will be emitted
      // to the parent on save.
      toDelete: [],

      // Has the data been copied into local state?
      dataCopied: false,

      // Available status messages (Will be copied from the this.messages prop)
      statusMessages: [],

      // Are there any unsaved changes in this modal?
      unsavedChanges: false,
    }
  },
  props: {
    type: {
      // Type of resource ('foodVendors' || 'rides' || 'shows')
      type: String,
      default: 'rides'
    },
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    messages: {
      // Available status messages
      type: Array
    }
  },
  methods: {
    save() {
      // Send the updated messages to the parent for saving

      // 1. Get confimration
      return MessageBox.confirm(
          this.$t('confirmStatusUpdate'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('save', this.statusMessages, this.toDelete);
        }).catch(() => {
          // User cancelled
        });
    },
    deleteMessage(idx) {
      var deleted = this.statusMessages.splice(idx, 1);
      this.toDelete.push(deleted[0]);
    },
    addMessage() {
      // Add a new message
      console.log('add message');
      this.statusMessages.push({
        types: [
          this.type,
        ],
        value: ''
      })
    },
    close() {
      if (!this.unsavedChanges) {
        this.$emit('close');
      } else {
        return MessageBox.confirm(
          this.$t('confirmClose'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('close');
          //this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      }
    },
  },
  watch: {
    statusMessages: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;

      if (this.visible) {
        // When the modal is opened reset the data
        var data = this.messages || [];
        this.statusMessages = JSON.parse(JSON.stringify(data));
      } else {
        // Othewise reset the data
        this.statusMessages = [];
      }

      this.toDelete = []; // Reset deleted message array
      this.unsavedChanges = false; // Reset unsaved changes status
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  margin-bottom: 0.5rem;
}

.delete-button {
  margin-left: 0.5rem
}

.empty {
  font-style: italic;
}
</style>