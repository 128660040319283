import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';

// Import views
import AdminSites from '../views/AdminSites';
import AdminTokens from '../views/AdminTokens';
import AdminUsers from '../views/AdminUsers';
import AdminUserTypes from '../views/AdminUserTypes';
import Birthdays from '../views/Birthdays';
import Dashboard from '../views/Dashboard';
import FoodVendors from '../views/FoodVendors';
import Login from '../views/Login';
import OpeningSchedules from '../views/OpeningSchedules';
import Rides from '../views/Rides';
import Shows from '../views/Shows';
import SiteView from '../views/SiteView';
import AutoLogin from '../views/AutoLogin';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    name: 'catchall',
    redirect: { name: 'login' }
  },
  {
    path: '/',
    name: 'index',
    redirect: { name: 'auto login', query: { idp: 'merlin' } }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/saml-login',
    name: 'auto login',
    component: AutoLogin,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/site/:siteId',
    component: SiteView,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard',
          requiresAuth: true,
        }
      },
      {
        path: 'rides',
        name: 'rides',
        component: Rides,
        meta: {
          title: 'Rides',
          requiresAuth: true,
        }
      },
      {
        path: 'rides/:rideId',
        name: 'ride',
        component: Rides,
        meta: {
          title: 'Rides',
          requiresAuth: true,
        }
      },
      {
        path: 'shows',
        name: 'shows',
        component: Shows,
        meta: {
          title: 'Shows',
          requiresAuth: true,
        }
      },
      {
        path: 'shows/:showId',
        name: 'show',
        component: Shows,
        meta: {
          title: 'Shows',
          requiresAuth: true,
        }
      },
      {
        path: 'food-vendors',
        name: 'food vendors',
        component: FoodVendors,
        meta: {
          title: 'Food Vendors',
          requiresAuth: true,
        }
      },
      {
        path: 'food-vendors/:vendorId',
        name: 'food vendor',
        component: FoodVendors,
        meta: {
          title: 'Food Vendors',
          requiresAuth: true,
        }
      },
      {
        path: 'birthdays',
        name: 'birthdays',
        component: Birthdays,
        meta: {
          title: 'Birthdays',
          requiresAuth: true,
        }
      },
      {
        path: 'birthdays/:birthdate',
        name: 'birthdate',
        component: Birthdays,
        meta: {
          title: 'Birthdays',
          requiresAuth: true,
        }
      },
      {
        path: 'opening-schedules',
        name: 'opening schedules',
        component: OpeningSchedules,
        meta: {
          title: 'Opening Hours',
          requiresAuth: true,
        }
      },
      {
        path: 'opening-schedules/:scheduleId',
        name: 'opening schedule',
        component: OpeningSchedules,
        meta: {
          title: 'Opening Hours',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/sites',
        name: 'sites',
        component: AdminSites,
        meta: {
          title: 'sites',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/sites/:adminSiteId',
        name: 'site',
        component: AdminSites,
        meta: {
          title: 'site',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/tokens',
        name: 'tokens',
        component: AdminTokens,
        meta: {
          title: 'Authentication Tokens',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/tokens/:tokenId',
        name: 'token',
        component: AdminTokens,
        meta: {
          title: 'Authentication Tokens',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/users',
        name: 'users',
        component: AdminUsers,
        meta: {
          title: 'Users',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/users/:userId',
        name: 'user',
        component: AdminUsers,
        meta: {
          title: 'Users',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/user-types',
        name: 'user types',
        component: AdminUserTypes,
        meta: {
          title: 'User Types',
          requiresAuth: true,
        }
      },
      {
        path: 'admin/user-types/:userTypeId',
        name: 'user type',
        component: AdminUserTypes,
        meta: {
          title: 'User Types',
          requiresAuth: true,
        }
      },
    ]
  }
];

const router = new VueRouter({
  routes
});

// Use a route guard to control page access
router.beforeEach((to, from, next) => {
  // set page title
  if(to && to.meta && to.meta.title) {
    document.title = 'Attractions.ninja | ' + to.meta.title;
  }
  else {
    document.title = 'Attractions.ninja';
  }

  let currentUser = firebase.auth().currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if(requiresAuth && !currentUser) {
    // If the user is not logged in redirect them to the user
    next({ name: 'login' });
  } else if (!requiresAuth && currentUser) {
    // If the user is logged in redirect them away from the login page
    next({ name: 'dashboard' });
  } else {
    next()
  }
})

export default router
