<template>
  <div class="log-entry">
    <p v-html="description"></p>

    <p class="grey">
      <span v-if="entry.time">{{
        $d(new Date(entry.time), 'mediumWithYearAndTimer')
      }}</span>
      <template v-if="entry.clientName">
        | {{ entry.clientName }}</template
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'LogEntry',
  computed: {
    name() {
      if (!this.entry) return null;

      // Derive a name for the updated resource based on it's type
      if (this.entry.collection === 'statusMessages') {
        return this.entry.data.value;
      } else {
        return this.entry.data.name;
      }
    },
    description() {
      if (!this.entry || !this.name) return null;

      var description;

      if (this.entry.collection === 'statusMessages') {
        // Updated status message presets require a distict set of messages
        if (this.entry.action === 'create') {
          description = this.$t('statusPresetCreated', { name: this.name })
        } else if (this.entry.action === 'update') {
          description = this.$t('statusPresetUpdated', { name: this.name })
        } else if (this.entry.action === 'delete') {
          description = this.$t('statusPresetDeleted', { name: this.name })
        }
      } else if (this.entry.action === 'update' && this.isStatus) {
        // Status message update
        description = this.$t('resourceStatusWasUpdated', { name: this.name });
      } else if (this.entry.action === 'create') {
        // Resource created
        description = this.$t('resourceWasCreated', { name: this.name });
      } else if (this.entry.action === 'update') {
        // Resource updated
        description = this.$t('resourceWasUpdated', { name: this.name });
      } else {
        // resource deleted
        description = this.$t('resourceWasDeleted', { name: this.name });
      }

      return description;
    },
    isStatus() {
      // Is this a status update entry
      if (!this.entry || !this.entry.collection) return false;

      return this.entry.collection.indexOf('Status') !== -1;
    }
  },
  props: {
    entry: Object
  }
}

</script>
<style lang="less" scoped>
.log-entry {
  p {
    &:first-child {
      margin-bottom: 0;
    }
    &:last-child {
      margin-top: 0.25rem;
    }
  }
}
</style>