import { Loading } from 'element-ui';

// Vue mixin. Adds the methods startLoading and stopLoading to a component
// (used to display a loading spinner)
export default {
  data() {
    return {
      loaded: false,
      spinnerTimmer: null,
      loadingSpinner: null,
      users: 0,
      timers: []
    }
  },
  methods: {
    startLoading() {
      // Display the loading spinner
      this.timers = [];
      this.loaded = false;
      this.users += 1;

      var spinnerTimmer = window.setTimeout(() => {
        if (!this.loadingSpinner) {
          this.loadingSpinner = Loading.service({ fullscreen: false, lock: true });
        }
      }, 250);

      this.timers.push(spinnerTimmer);
    },
    stopLoading() {
      // Hide the loading spinner
      this.loaded = true;
      this.users -= 1;

      for (var i = 0, len = this.timers.length; i < len; i++) {
        if (this.timers[i]) {
          window.clearTimeout(this.timers[i]);
        }
      }

      if (this.loadingSpinner && this.loadingSpinner.close && !this.users) {
        this.loadingSpinner.close();
        this.loadingSpinner = null;
      }
    }
  }
}
