<template>
  <svg width="0" height="0" style="display: none">
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.04 23.027" id="gear">
      <path d="M8.861 22.503a11.228 11.228 0 01-3.245-1.346.809.809 0 01-.212-1.188 1.678 1.678 0 00-2.353-2.359.808.808 0 01-1.188-.216 11.233 11.233 0 01-1.337-3.243.81.81 0 01.687-.99 1.678 1.678 0 00.006-3.33.808.808 0 01-.683-.992A11.24 11.24 0 011.88 5.603a.809.809 0 011.182-.218 1.678 1.678 0 002.355-2.35.807.807 0 01.22-1.181A11.233 11.233 0 018.868.526a.808.808 0 01.99.677 1.677 1.677 0 003.325 0 .808.808 0 01.99-.678 11.249 11.249 0 013.231 1.334.809.809 0 01.221 1.181 1.678 1.678 0 002.355 2.35.808.808 0 011.181.218 11.215 11.215 0 011.343 3.232.809.809 0 01-.682.992 1.678 1.678 0 00.005 3.329.81.81 0 01.687.99 11.221 11.221 0 01-1.336 3.244.808.808 0 01-1.188.216 1.678 1.678 0 00-2.354 2.359.807.807 0 01-.212 1.189 11.228 11.228 0 01-3.245 1.344.792.792 0 01-.189.023.809.809 0 01-.8-.714 1.679 1.679 0 00-3.334 0 .808.808 0 01-.995.691zm5.69-1.785a9.526 9.526 0 001.347-.559 3.3 3.3 0 014.279-4.289 9.649 9.649 0 00.555-1.35 3.294 3.294 0 01-.011-6.046 9.771 9.771 0 00-.562-1.35 3.293 3.293 0 01-4.272-4.261 9.661 9.661 0 00-1.353-.56 3.292 3.292 0 01-6.03 0 9.7 9.7 0 00-1.353.558 3.293 3.293 0 01-4.27 4.264 9.554 9.554 0 00-.562 1.35 3.295 3.295 0 01-.015 6.046 9.648 9.648 0 00.555 1.35 3.3 3.3 0 014.279 4.289 9.526 9.526 0 001.347.559 3.3 3.3 0 016.062 0zm-7.858-9.203a4.827 4.827 0 114.827 4.827 4.832 4.832 0 01-4.827-4.827zm1.616 0a3.211 3.211 0 103.21-3.21 3.214 3.214 0 00-3.209 3.21z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.087 24.386" id="ride">
      <path d="M8.112 23.886a1.1 1.1 0 01-1.019-1.5l.494-1.743a9.336 9.336 0 01-2.161-2.223 1.9 1.9 0 11-.9-1.639 9.252 9.252 0 01-.734-3.623c0-.114 0-.228.006-.341a1.9 1.9 0 11.324-2.12 9.27 9.27 0 012.2-3.912h-.117a1.9 1.9 0 111.852-1.438A9.227 9.227 0 0112 3.937a1.9 1.9 0 112.246.01 9.231 9.231 0 014.187 1.625 1.9 1.9 0 111.356 1.169 9.274 9.274 0 012.181 3.735 1.9 1.9 0 11.38 2.181q.013.249.013.5a9.251 9.251 0 01-.766 3.7 1.9 1.9 0 11-.787 1.542v-.1a9.331 9.331 0 01-2.437 2.487l.421 1.638a1.1 1.1 0 01-1.032 1.463zm9.758-5.051a7.433 7.433 0 10-9.733-.125l1.788-6.3a3.239 3.239 0 016.338.176zm-6.128-5.675a1.334 1.334 0 101.334-1.335 1.336 1.336 0 00-1.334 1.334z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.559 22.665" id="show">
      <path d="M17.017 22.165a.778.778 0 01-.778-.807 12.381 12.381 0 011.418-5.231.625.625 0 01-.005-.081v-2.388a.622.622 0 01.035-.207A18.113 18.113 0 0113.8 6.734h-.074a3.232 3.232 0 01-2.447-1.114 3.231 3.231 0 01-2.442 1.114h-.074a18.117 18.117 0 01-3.882 6.7.624.624 0 01.039.219v2.387a.627.627 0 01-.009.1 12.384 12.384 0 011.406 5.209.778.778 0 01-.778.807H1.295a.778.778 0 01-.778-.778V3.831A3.26 3.26 0 01.5 3.5v-3h21.559v3a3.259 3.259 0 01-.018.342v17.545a.778.778 0 01-.778.778zm.842-1.556h2.626v-3.941h-1.348a10.931 10.931 0 00-1.279 3.941zm-15.785 0H4.7a10.947 10.947 0 00-1.277-3.939h-1.35zM18.9 15.421h1.585v-1.14H18.9zm-15.229 0v-1.138h-1.6v1.137zm16-2.386a13.228 13.228 0 01-1.58-4.214.523.523 0 011.021-.229 12.6 12.6 0 001.371 3.763V6.273a3.219 3.219 0 01-1.664.461h-.106a3.232 3.232 0 01-2.443-1.115 3.259 3.259 0 01-.957.753 16.069 16.069 0 004.166 6.662zm-16.6 0a16.072 16.072 0 004.173-6.662 3.257 3.257 0 01-.956-.753 3.232 3.232 0 01-2.444 1.114h-.106a3.219 3.219 0 01-1.665-.462v6.082a12.614 12.614 0 001.372-3.763.523.523 0 111.02.23 13.226 13.226 0 01-1.579 4.212zM17.066 3.5a1.649 1.649 0 001.648 1.647h.106A1.649 1.649 0 0020.468 3.5V2.09h-3.4zm-4.992 0a1.649 1.649 0 001.648 1.647h.106A1.649 1.649 0 0015.476 3.5V2.09h-3.4zm-4.991 0A1.649 1.649 0 008.73 5.144h.107A1.649 1.649 0 0010.484 3.5V2.09h-3.4zm-4.993 0a1.649 1.649 0 001.648 1.644h.106A1.649 1.649 0 005.492 3.5V2.09H2.09z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.895 20.468" id="cake">
      <path d="M.5 19.968v-9.184a2.365 2.365 0 012.362-2.363h.405V5.762a.9.9 0 111.8 0v2.66h2.467v-3.2a.9.9 0 011.8 0v3.2H11.9v-3.2a.9.9 0 011.8 0v3.2h2.463v-2.66a.9.9 0 111.8 0v2.66h1.059a2.366 2.366 0 012.378 2.362v9.184zm1.8-1.8h17.29v-3.62a3.936 3.936 0 01-1.569-.848 1.9 1.9 0 00-2.7 0 3.679 3.679 0 01-4.939 0 1.9 1.9 0 00-2.7 0 3.677 3.677 0 01-4.938 0 4.716 4.716 0 00-.447-.329zm9.2-5.876a1.9 1.9 0 002.7 0 3.676 3.676 0 014.939 0 4.7 4.7 0 00.448.329v-1.837a.56.56 0 00-.56-.56H2.862a.56.56 0 00-.56.56v.652a3.931 3.931 0 011.568.848 1.9 1.9 0 002.7 0 3.675 3.675 0 014.938 0zm5.48-8.186a.841.841 0 01-.616-1.248l.708-1.285.708 1.284a.841.841 0 01-.616 1.248.791.791 0 01-.184 0zm-12.9 0a.841.841 0 01-.616-1.248l.705-1.285.707 1.284a.841.841 0 01-.615 1.249.791.791 0 01-.184 0zm8.637-.536a.841.841 0 01-.617-1.25l.707-1.284.707 1.284a.841.841 0 01-.614 1.249h-.092c-.031 0-.063.003-.094 0zm-4.371 0a.841.841 0 01-.615-1.249l.704-1.285.707 1.284a.841.841 0 01-.616 1.249h-.092c-.031 0-.061.003-.091 0z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.032 23.032" id="clock">
      <path d="M.5 11.516a11.016 11.016 0 1111.016 11.016A11.016 11.016 0 01.5 11.516zm2.2 0a8.82 8.82 0 108.82-8.82 8.83 8.83 0 00-8.82 8.82zm7.756 1.07l-.035-5.906a1.068 1.068 0 011.063-1.08h.005a1.068 1.068 0 011.069 1.064l.021 3.778h3.408a1.07 1.07 0 110 2.139z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.258 21.525" id="dashboard">
      <path d="M23.481 20.883a.932.932 0 01-.3-1.283 11.214 11.214 0 001.6-4.4h-2.512a.931.931 0 010-1.862h2.623a11.2 11.2 0 00-.836-3.98l-2.433 1.059a.931.931 0 01-.741-1.708L23.207 7.7a11.355 11.355 0 00-2.319-2.682l-2.006 2.133a.931.931 0 11-1.356-1.275l1.831-1.947a11.189 11.189 0 00-4.288-1.475v2.535a.931.931 0 01-1.862 0V2.37a11.187 11.187 0 00-4.993 1.381l1.6 2.219A.932.932 0 018.3 7.057L6.661 4.781A11.353 11.353 0 004.051 7.7l2.316 1.01a.931.931 0 01-.743 1.707L3.2 9.362a11.2 11.2 0 00-.834 3.915h2.622a.931.931 0 110 1.861H2.464a11.239 11.239 0 001.376 4.073.931.931 0 11-1.616.924A13.127 13.127 0 016.28 2.754l.046-.035a.937.937 0 01.1-.065 13.1 13.1 0 0114.852.312.932.932 0 01.113.082 13.127 13.127 0 013.37 17.539.93.93 0 01-1.283.3zm-12.308-5.221a1.949 1.949 0 012.227-1.927l2.023-2.7a.931.931 0 011.49 1.118l-2.023 2.7a1.947 1.947 0 11-3.719.81z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10"></path>
    </symbol>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.609 21.66" id="food">
      <path data-name="Path 154" d="M12.473 3.96l.033-.031-.033.031c-.3.287-2.508 2.536-.724 4.931l-.8.8L2.8 1.501a.792.792 0 00-.061-.056L1.766.467A.866.866 0 00.6.41C-.063.956-.218 2.001.327 3.318c.866 2.09 3.434 5.15 5.3 6.977a2.588 2.588 0 001.339.746 2.189 2.189 0 001.836-.549 3.216 3.216 0 00.253-.241l.662.665-9.246 9.267a.866.866 0 000 1.224.866.866 0 001.224 0l9.251-9.262 9.113 9.161a.866.866 0 001.224 0 .866.866 0 000-1.224l-9.113-9.163.846-.847c2.292 1.438 4.374-.62 4.649-.91l.031-.033-.031.033 3.7-3.768a.866.866 0 00-.012-1.224.866.866 0 00-1.224.012l-3.7 3.768-.032.034s-1.469 1.706-2.959.215.217-2.976.217-2.976l.034-.032 3.754-3.711A.866.866 0 0017.45.256a.866.866 0 00-1.224-.007L12.472 3.96zM2.358 3.519L7.84 9.031l-.014.016c-.136.146-.3.339-.538.3a.932.932 0 01-.445-.283 28.929 28.929 0 01-4.485-5.545zM16.28 7.593l3.768-3.782a.638.638 0 000-.9.638.638 0 00-.9 0L15.38 6.693a.638.638 0 000 .9.638.638 0 00.9-.003zm-1.261-1.346l3.768-3.782a.638.638 0 000-.9.638.638 0 00-.9 0l-3.768 3.782a.638.638 0 000 .9.638.638 0 00.9-.003z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" />
    </svg>
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" id="menu">
      <path d="M28 22.026a.656.656 0 00-.215-.472.654.654 0 00-.473-.215H.687a.654.654 0 00-.472.215.656.656 0 00-.215.472c0 .172.072.33.215.473a.658.658 0 00.472.215h26.626a.658.658 0 00.472-.215.658.658 0 00.215-.473zM28 14a.66.66 0 00-.215-.473.657.657 0 00-.473-.214H.687a.657.657 0 00-.472.214A.66.66 0 000 14a.66.66 0 00.215.473.657.657 0 00.472.214h26.626a.657.657 0 00.472-.214A.66.66 0 0028 14zM28 5.974a.658.658 0 00-.215-.473.658.658 0 00-.473-.215H.687a.658.658 0 00-.472.215.658.658 0 00-.215.473c0 .172.072.329.215.472a.654.654 0 00.472.215h26.626a.654.654 0 00.472-.215.656.656 0 00.215-.472z" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" />
    </svg>
  </svg>
</template>

<script>
export default {
  name: 'IconSprite'
}
</script>