<template>
  <li class="bt-menu-item">
    <router-link :to="to"><slot></slot></router-link>
  </li>
</template>

<script>
export default {
  name: 'BtMenuItem',
  props: [
    'to' // Should be a link in string or Vue router format
  ]
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

li {
  font-family: @heading-font;
}

a {
  text-decoration: none;
  color: white;
  display: block;
  height: 100%;
  border-left: .25rem solid transparent;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  transition:
    background-color 0.25s ease,
    color 0.25s ease,
    fill 0.25s ease,
    border-color 0.25s ease;
  fill: white; // Target any svg in the slot

  &:hover {
    background: rgba(65, 104, 212, 0.5);
  }

  &.router-link-exact-active {
    color: @primary;
    background: #f8f9fb;
    border-color: @primary;
    fill: @primary;
  }
}

.bt-menu-item {
  height: 3em;
}
</style>