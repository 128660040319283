export default class WeatherService {
  constructor(baseUrl = {}) {
    if (!baseUrl) throw new Error('No base URL provided')
    this.baseUrl = baseUrl
  }

  //get weather here
  // need to send both town and country code to get proper response
  getWeatherByTown(town, country) {
    var url = `${this.baseUrl}?place=${town + ',' + country}`
    return this._request(url, 'GET')
  }

  async _request(url, method) {
    // Build the request options
    var opts = {
      method: method || 'GET',
      mode: 'cors',
    }
    try {
      const res = await fetch(url, opts)
      if (!res.ok) {
        var err = new Error(res.statusText)
        err.status = res.status
        throw err
      }
      //this is ok 200
      //but if nothing found, it still returns 200 and the site will show an error.
      //TODO find a way to catch if the res has no data
      return res.json()
    } catch (err) {
      // If no status is set assume a 500 error
      if (!err.status) err.status = 500
      throw err
    }
  }
}
