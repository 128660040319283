<template>
  <div class="auto-login">
    <img class="logo" src="../assets/img/attractions-ninja.svg" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AutoLogin',
  created() {
    //return;
    if (this.$route.query.idp) {
      // Attempt to initiate SAML login
      return this.login({ idp: this.$route.query.idp })
        .then(() => {
          // this.$router.push({ name: 'auto login' }); // TODO: Remove this step
        })
        .catch(() => {
          // If there's an error redirect the user to the login page
          this.$router.push({ name: 'login' })
        })
    }
    {
      // No identity provider specified. Redirect the user to the login page
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    ...mapActions(['login'])
  }
}
</script>

<style lang="less">
@import '../styles/vars';

.auto-login {
  background: @login-gradient;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 90vw;
  max-width: 20rem;
}
</style>
