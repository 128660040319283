<template>
  <bt-modal :visible="visible" @close="close" :title="modalTitle">
    <template v-slot:body>
      <el-form :rules="rules" :model="formData" ref="form">
        <el-form-item prop="name" :label="$t('name')" style="margin-bottom: 2rem" v-if="!override">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>

        <table>
          <tr>
            <th></th>

            <th>
              {{ $t('from') }}
            </th>

            <th>
              {{ $t('to') }}
            </th>
          </tr>

          <tr v-for="(day, idx) in formData.days" :key="day.idx">
            <th scope="row">{{ $t(day.name) }}</th>

            <td>
              <el-form-item :prop="'days.' + idx + '.opens'">
                <template v-slot:label>
                  <span class="screen-reader-text">{{ $t('from') }}</span>
                </template>

                <el-time-select
                  v-model="day.opens"
                  :clearable="false"
                  :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: day.closes,
                  }"
                  :placeholder="$t('opens')"
                ></el-time-select>
              </el-form-item>
            </td>

            <td>
              <el-form-item :prop="'days.' + idx + '.closes'">
                <template v-slot:label>
                  <span class="screen-reader-text">{{ $t('to') }}</span>
                </template>

                <el-time-select
                  v-model="day.closes"
                  :clearable="false"
                  :picker-options="{
                    start: day.opens,
                    step: '00:15',
                    end: '23:45',
                  }"
                  :placeholder="$t('closes')"
                ></el-time-select>
              </el-form-item>
            </td>
          </tr>
        </table>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button v-if="!override" type="success" @click="save(false)" :disabled="!unsavedChanges">
        {{ $t('save') }}
      </el-button>

      <el-button v-if="override" type="success" @click="save(true)" :disabled="!unsavedChanges">
        {{ $t('saveTemporaryOverride') }}
      </el-button>

      <el-button @click="close">
        {{ $t('close') }}
      </el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal';
import { Form, FormItem, TimeSelect, Input, MessageBox } from 'element-ui';

export default {
  name: 'ScheduleModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-time-select': TimeSelect,
    'el-input': Input
  },
  computed: {
    modalTitle() {
      // Returns a title for the modal
      if (this.formData && this.formData.name) {
        // Use the schedule name if one is available
        return this.formData.name
      } else {
        // Otherwise return 'Unnamed schedule'
        return this.$t('unnamedSchedule')
      }
    }
  },
  data() {
    return {
      dataCopied: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pleaseEnterAScheduleName'),
            trigger: 'blur',
          },
        ],
      },
      unsavedChanges: false, // Are there crrently unsaved changes within this modal?
      formData: null // The schedule property is copied into this.formData for editing
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save(override) {
      if (this.unsavedChanges) {
        return this.$refs.form.validate().then(() => {
          return MessageBox.confirm(
            this.$t('confirmSaveChanges'),
            this.$t('warning'),
            {
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel'),
              type: 'warning'
            }
          );
        }).then(() => {
          if (override) {
            this.$emit('saveOverride', this.formData);
          } else {
            this.$emit('save', this.editing, this.formData);
          }
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      } else {
        this.$emit('close');
      }
    }
  },
  props: {
    // When editing a ride the schedule property is used to pass the details into
    // this component. This data will be copied to this.formData to avoid
    // mutating the property.
    schedule: Object,
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    override: {
      // Are we overriding an existing schedule
      type: Boolean,
      default: false
    },
    editing: {
      // Are we editing an existing ride or creating a new one?
      type: Boolean,
      default: false,
    },
  },
  watch: {
    formData: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;

      if (this.visible) {
        // When the modal is opened reset the data
        // Check this.editing
        var data = this.schedule && this.editing ? this.schedule : {}

        this.formData = Object.assign({
          name: undefined,
          active: undefined,
          days: [
            {
              idx: 1,
              name: 'monday',
              opens: '09:00',
              closes: '18:00'
            },
            {
              idx: 2,
              name: 'tuesday',
              opens: '09:00',
              closes: '18:00'
            },
            {
              idx: 3,
              name: 'wednesday',
              opens: '09:00',
              closes: '18:00'
            },
            {
              idx: 4,
              name: 'thursday',
              opens: '09:00',
              closes: '18:00'
            },
            {
              idx: 5,
              name: 'friday',
              opens: '09:00',
              closes: '18:00'
            },
            {
              idx: 6,
              name: 'saturday',
              opens: '09:00',
              closes: '18:00'
            },
            {
              idx: 7,
              name: 'sunday',
              opens: '09:00',
              closes: '18:00'
            }
          ]
        }, JSON.parse(JSON.stringify(data)));
      } else {
        // Othewise reset the data
        this.formData = null
      }

      this.unsavedChanges = false;
    },
  },
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin: 0;
}

td, th {
  padding: 0.25rem 0.5rem;
}

table /deep/ .el-date-editor.el-input {
  width: auto;
  max-width: 10rem;
}

@media only screen and (max-width: 400px) {
  table {
    font-size: 0.8rem;;
  }
}
</style>