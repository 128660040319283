<template>
  <div class="timeAndWeather" v-if="temperature">
    <!-- Time here -->
    <!-- set time based on location -->
    <span class="time">
      {{ siteTime }}
    </span>
    <!-- temperature -->

    <span class="location">
      {{ this.town }}, {{ this.country }}
    </span>

    <div class="temp">
      {{ temperature }}&#0176;C
    </div>
    <el-tooltip placement="bottom" effect="light" :content="weatherDescription">
      <img :src="weatherIcon" :alt="weatherDescription" />
    </el-tooltip>
  </div>
</template>

<script>
import { Tooltip, Message } from 'element-ui'
import weather from '../weather-api'
import { mapState, mapGetters } from 'vuex'
import Loading from '../mixins/Loading'
import { DateTime } from 'luxon';

export default {
  name: 'TimeAndWeather',
  components: {
    'el-tooltip': Tooltip,
  },
  created() {
    this.getWeather()
  },
  data() {
    return {
      weatherData: {},
      town: 'London',
      country: 'GB',
    }
  },
  computed: {
    ...mapState(['selectedSiteId']),
    ...mapGetters(['currentSite']),

    temperature() {
      if (
        !this.weatherData ||
        !this.weatherData.main ||
        !this.weatherData.main.temp
      )
        return null
      return Number(this.weatherData.main.temp.toFixed(0))
    },
    weatherDescription() {
      return this.weatherData.weather[0].main.toString()
    },
    weatherIcon() {
      return (
        'https://openweathermap.org/img/wn/' +
        this.weatherData.weather[0].icon.toString() +
        '.png'
      )
    },
    siteTime() {
      // use the currentsite timezone
      var timeZone = this.currentSite.timeZone
      return DateTime.now().setZone(timeZone).toFormat('HH:mm ccc dd MMM');
    },
  },
  methods: {
    getWeather() {
      this.startLoading()
      //need a town and a country code from the selected site
      if (this.currentSite && this.currentSite.town && this.currentSite.country) {
        this.town = this.currentSite.town
        this.country = this.currentSite.country
      } else {
        //   if there is no site address provided, then don't show local data
        this.stopLoading()
        return null
      }

      return weather
        .getWeatherByTown(this.town, this.country)
        .then((response) => {
          this.weatherData = response
          // if the town and country aren't found in the api
          // this results empty so we don't show anything
          this.stopLoading()
          if (!this.weatherData) {
            console.log(
              'the location: ' +
                this.town +
                ',' +
                this.country +
                " didn't result in a weather response."
            )
          }
        })
        .catch((err) => {
          this.stopLoading()
          console.log(err)
          Message.error(this.$t('errorGetWeather'))
        })
    },
  },
  mixins: [Loading],
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.timeAndWeather {
  display: flex;
  align-items: center;
  margin: 0 2vw 0 2vw;
}

.time, .location, .temp {
  margin: 0 0.25rem;
}

@media only screen and (max-width: 1100px) {
  .location {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .temp {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .time {
    display: none;
  }
}
</style>
