<template>
  <bt-modal :visible="visible" @close=close :title="modalTitle">
    <template v-slot:body>
      <el-form ref="form" :model="rideData" label-width="120px" :rules="rules" label-position="top">
        <div class="inline-form-section">
        <el-form-item :label="$t('rideName')" prop="name">
          <el-input v-model="rideData.name"></el-input>
        </el-form-item>

        <el-form-item :label="$t('shortRideName')" prop="name">
          <el-input v-model="rideData.shortName"></el-input>
        </el-form-item>
        </div>

        <!-- Blip ID -->
        <el-form-item :label="$t('blipId')" v-if="blipSite">
          <el-input v-model="rideData.blipId"></el-input>
        </el-form-item>

        <!-- Attractions.io ID -->
        <el-form-item :label="$t('attractionsIoId')" v-if="aioSite">
          <el-input v-model="rideData.attractionsIoId"></el-input>
        </el-form-item>

        <div class="inline-form-section">
          <!-- Latitude -->
          <el-form-item :label="$t('latitude')" prop="lat">
            <el-input v-model.number="rideData.lat"></el-input>
          </el-form-item>

          <!-- Longitude -->
          <el-form-item :label="$t('longitude')" prop="lng">
            <el-input v-model.number="rideData.lng"></el-input>
          </el-form-item>
        </div>

        <el-form-item :label="$t('active')">
          <el-switch v-model="rideData.active"/>
        </el-form-item>
      </el-form>
    </template>

    <template v-slot:footer>
      <el-button type="success" @click="save" :disabled="!unsavedChanges">{{ $t('save') }}</el-button>
      <el-button @click="close">{{ $t('close') }}</el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal'
import { Form, FormItem, Input, Button, Switch, MessageBox } from 'element-ui';
import { mapGetters } from 'vuex';

export default {
  name: 'RideModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-button': Button,
    'el-switch': Switch
  },
  computed: {
    modalTitle() {
      // Returns a title for the modal
      if (this.rideData && this.rideData.name) {
        // Use the ride name if one is available
        return this.rideData.name
      } else {
        // Otherwise return 'Unnamed ride' if we're editing
        return this.$t('unnamedRide')
      }
    },
    ...mapGetters([
      'blipSite',
      'aioSite'
    ])
  },
  data() {
    return {
      rideData: null, // Will hold the data we're currently editing
      dataCopied: false, // Indicates data has been copied into local state
      unsavedChanges : false, // Are there unsaved changes?
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter a ride name',
            trigger: 'blur',
          },
        ],
        lat: [
          { type: 'number', min: -90, max: 90, message: this.$t('latitudeError'), trigger: 'change' }
        ],
        lng: [
          { type: 'number', min: -180, max: 180, message: this.$t('longitudeError'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    close() {
      if (!this.unsavedChanges) {
        this.$emit('close');
      } else {
        return MessageBox.confirm(
          this.$t('confirmClose'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('close');
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      }
    },
    save() {
      if (this.unsavedChanges) {
        return MessageBox.confirm(
          this.$t('confirmSaveChanges'),
          this.$t('warning'),
          {
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }
        ).then(() => {
          this.$emit('save', this.editing, this.rideData);
          this.unsavedChanges = false;
        }).catch(() => {
          // User cancelled
        });
      } else {
        this.$emit('close');
      }
    }
  },
  props: {
    // When editing a ride the ride property is used to pass the details into
    // this component. This data will be copied to this.rideData to avoid
    // mutating the property.
    ride: Object,
    visible: {
      // Is the modal currently open?
      type: Boolean,
      default: false,
    },
    editing: {
      // Are we editing an existing ride or creating a new one?
      type: Boolean,
      default: false,
    },
  },
  watch: {
    rideData: {
      handler() {
        if (this.dataCopied) {
          this.unsavedChanges = true;
        } else {
          this.dataCopied = true;
        }
      },
      deep: true
    },
    visible() {
      this.dataCopied = false;

      if (this.visible) {
        // When the modal is opened reset the data
        // Check this.editing
        var data = this.ride ? this.ride : {}

        this.rideData = Object.assign({
          name: undefined,
          shortName: undefined,
          lat: undefined,
          lng: undefined,
          active: undefined,
          blipId: undefined,
          attractionsIoId: undefined
        }, data);
      } else {
        // Othewise reset the data
        this.rideData = null
      }

      this.unsavedChanges = false;
    },
  },
}
</script>

<style lang="less" scoped>
.form-col-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
}

.inline-form-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
  grid-column-gap: 1em;
}
</style>
