<template>
  <bt-modal :visible="visible" @close="$emit('close')" :title="title">
    <!-- modal body -->
    <template v-slot:body>
      <!-- Form -->
      <el-form
        ref="form"
        :model="formData"
        label-width="120px"
        :rules="rules"
        label-position="top"
      >
        <!-- name -->
        <el-form-item :label="$t('userTypeName')" prop="name">
          <el-input
            :placeholder="$t('userTypeName')"
            v-model="formData.name"
            ref="name"
          />
        </el-form-item>

        <!-- claimValue -->
        <el-form-item prop="claimValue">
          <template v-slot:label>
            <span>{{ $t('userRoleValue') }}</span>
            <el-popover
              width="400"
              trigger="hover"
              :content="$t('roleClaimDescription')"
            >
              <template v-slot:reference>
                <i class="si-question-circle explanation" />
              </template>
            </el-popover>
          </template>
          <el-input
            :placeholder="$t('userRoleValue')"
            v-model="claimValueEntry"
            ref="claimValue"
          >
            <template slot="prepend">role:</template>
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('permissions')" prop="permissions">
          <!-- Permissions table -->
          <table class="permission-table">
            <!-- Heading row -->
            <tr>
              <th></th>
              <th scope="col">Read</th>
              <th scope="col">Write</th>
              <th scope="col">Delete</th>
            </tr>

            <tr v-for="(group, idx) in availablePermissions" :key="idx">
              <th scope="row">{{ $t(group.label) }}</th>
              <!-- Get the translation key from the availablePermissions array below -->
              <td>
                <el-switch
                  v-if="group.read"
                  :value="permissionsObj['read' + ':' + group.type]"
                  @change="togglePermission('read' + ':' + group.type)"
                />
              </td>
              <td>
                <el-switch
                  v-if="group.write"
                  :value="permissionsObj['write' + ':' + group.type]"
                  @change="togglePermission('write' + ':' + group.type)"
                />
              </td>
              <td>
                <el-switch
                  v-if="group.delete"
                  :value="permissionsObj['delete' + ':' + group.type]"
                  @change="togglePermission('delete' + ':' + group.type)"
                />
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>
    </template>

    <!-- modal footer -->
    <template v-slot:footer>
      <el-button type="success" @click="handleSave">
        {{ $t('save') }}</el-button
      >
      <el-button @click="$emit('close')">
        {{ $t('close') }}
      </el-button>
    </template>
  </bt-modal>
</template>

<script>
import BtModal from '../BtModal'
import { Form, FormItem, Button, Switch, Input, Message, Popover } from 'element-ui'

export default {
  name: 'UserTypeAddModal',
  components: {
    'bt-modal': BtModal,
    'el-form': Form,
    'el-form-item': FormItem,
    'el-button': Button,
    'el-input': Input,
    'el-switch': Switch,
    'el-popover': Popover
  },
  computed: {
    claimValueEntry: {
      get() {
        if (!this.formData || !this.formData.claimValue) return null;

        // Remove the 'site:' from the start
        if (this.formData.claimValue.substr(0, 5) === 'role:') {
          return this.formData.claimValue.substr(5);
        } else {
          return this.formData.claimValue;
        }
      },
      set(newValue) {
        this.formData.claimValue = 'role:' + newValue;
      }
    },
    permissionsObj() {
      // Convert the permissions array into an object.
      // [ 'read:ride' ] => { 'read:ride': true }
      if (!this.formData || !this.formData.permissions) return {}

      var result = {}

      this.formData.permissions.forEach((permission) => {
        result[permission] = true
      })

      return result
    },
  },
  data() {
    var validatePerms = (rule, value, callback) => {
      console.log(value);
      // Return an error if no permissions have been selected
      if (this.formData.permissions.length === 0) {
        callback(this.$t('pleaseSelectPermissions'));
      } else {
        callback();
      }
    }

    var validateUniqueClaim = (rule, value, callback) => {
      // Add the 'role:' onto the start of the comparison value
      var newValue = 'role:' + value;
      // Make sure the claimValue is unique
      if (this.claimValues.includes(newValue)) {
        callback(new Error(this.$t('claimValueAlreadyExists')));
      } else {
        callback();
      }
    }

    return {
      title: this.$t('addNewUserType'),
      formData: {
        name: null,
        claimValue: '',
        permissions: [],
      },
      errorMessage: '',
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pleaseEnterAUserTypeName'),
            trigger: 'blur',
          },
        ],
        claimValue: [
          {
            // Must include a claimValue
            required: true,
            message: this.$t('pleaseEnterARole'),
            trigger: 'blur',
          },
          {
            // claimValue must not include spaces
            pattern: /^\S+$/,
            message: this.$t('ClaimValuesValidation'),
            trigger: 'blur',
          },
          {
            // claimValue must be unique
            validator: validateUniqueClaim,
            trigger: 'blur'
          }
        ],
        permissions: [
          { validator: validatePerms, trigger: 'blur' }
        ]
      },
      availablePermissions: [
        {
          label: 'rides', // This is a key in the locale files (e.g. en-gb.json)
          type: 'ride', // the second part in the 'read:ride', 'write:ride' etc. strings
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'rideStatuses',
          type: 'ride_status',
          read: true,
          write: true,
        },
        {
          label: 'shows',
          type: 'show',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'showStatuses',
          type: 'show_status',
          read: true,
          write: true,
        },
        {
          label: 'showTypes',
          type: 'show_type',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'showVenues',
          type: 'show_venue',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'fAndB',
          type: 'fandb',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'fAndBStatuses',
          type: 'fandb_status',
          read: true,
          write: true,
        },
        {
          label: 'statusMessages',
          type: 'message',
          write: true,
          delete: true
        },
        {
          label: 'openingHours',
          type: 'schedule',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'birthdays',
          type: 'birthday',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'userTypes',
          type: 'user_type',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'tokens',
          type: 'token',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'sites',
          type: 'site',
          read: true,
          write: true,
          delete: true,
        },
        {
          label: 'logEntries',
          type: 'log_entry',
          read: true
        }
      ],
    }
  },
  methods: {
    togglePermission(permission) {
      // This function adds the value of the permission property to
      // this.formData.permissions, or takes it away if it is alread there
      if (!permission) return

      // Find the position of the cermission in the array
      var idx = this.formData.permissions.indexOf(permission)

      if (idx === -1) {
        // This permission isn't currently listed in the permissions array. Add it.
        this.formData.permissions.push(permission)
      } else {
        // This permission is listed . Take it out of the array
        this.formData.permissions.splice(idx, 1)
      }
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        var hasPermissions = this.formData.permissions.length != 0

        // Use the list of current claimValues to validate the new one
        if (valid && hasPermissions) {
          this.$emit('save', this.formData)
          this.formData = {
            name: null,
            claimValue: '',
            permissions: [],
          }
        } else {
          hasPermissions ? true : Message.error(this.$t('pleaseSelectPermissions'));
          return;
        }
      })
    },
  },
  props: {
    claimValues: Array,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.$nextTick(() => {
          if (this.$refs.name) {
            this.$refs.name.focus()
          }
        })
      } else {
        this.formData = {
          name: null,
          claimValue: '',
          permissions: [],
        }
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
