<template>
  <div class="file-select">
    <input
      type="file"
      class="file-select-upload"
      ref="selector"
      :accept="accept"
      @change="inputChanged"
    />
    <div
      class="input-wrapper"
      ref="wrapper"
      :class="{ hover: hover, invalid: invalid }"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @drop.prevent="drop"
      @dragover.prevent=""
    >
      <div class="file-select-inner">
        <template class="file" v-if="files">
            <span class="name">{{files[0].name}}</span> <span class="size">{{fileSize}}</span>
        </template>
        <span class="placeholder" v-else>{{placeholder}}</span>
      </div>
      <el-button class="clear" v-if="files" @click="clear">{{ $t('clear' ) }}</el-button>
      <el-button class="select" type="primary" @click="openDialog">{{ $t('browse' ) }}</el-button>
    </div>
    <div class="caption">{{caption}}</div>
  </div>
</template>

<script>
import { Button } from 'element-ui';
export default {
  name: 'FileSelect',
  components: {
    'el-button': Button
  },
  props: {
    placeholder: {
      type: String,
      default: function() {
        return this.$t('selectAFile');
      }
    },
    accept: {
      type: String,
      default: '.gif,.jpg,.png'
    },
    caption: {
      type: String
    },
    value: {
      type: File
    }
  },
  data() {
    return {
      files: null,
      hover: false,
      invalid: false,
      pendingClass: false
    }
  },
  computed: {
    fileSize() {
      if (!this.files) return null;

      var fileSize = this.files[0].size; // in bytes

      if (fileSize < 1048576) {
        // Less than 1mb
        return (fileSize / 1024).toFixed(2) + ' KB';
      } else {
        // More than 1mb
        return (fileSize / 1048576).toFixed(2) + ' MB';
      }
    },
    accepted() {
      var accept = this.accept.split(',');
      var accepted = [];

      for (var i = 0, len = accept.length; i< len; i++) {
        let value = accept[i].trim();
        if (value[0] === '.') {
          accepted.push({
            type: 'extension',
            value: accept[i].trim()
          });
        } else {
          accepted.push({
            type: 'mime',
            value: accept[i].trim()
          });
        }
      }

      return accepted;
    }
  },
  methods: {
    openDialog() {
      this.$refs.selector.click();
    },
    validateFileType(file) {
      // Determine if a given file is an acccepted format.
      // NOTE: This is only used when dragging a file into this component.
      // The file dialog uses the native file input validation.
      for (var i = 0, len = this.accepted.length; i < len; i++) {
        if (this.accepted[i].type === 'extension') {
          // Does the file extension match?
          let extension = '.' + file.name.split('.')[1];
          if (extension && extension === this.accepted[i].value) return true;
        } else {
          // Does the mime type match?
          if (this.accepted[i].value === file.type) return true;
        }
      }
      // If we get to this point then the file hasn't matched any of the
      // accepted formats.
      return false;
    },
    dragEnter() {
      // Change appearance to indicate the file may be dropped
      this.hover = true;
      this.pendingClass = true;
      this.$nextTick(() => {
        this.pendingClass = false;
      })
    },
    dragLeave() {
      if (!this.pendingClass) {
        this.hover = false;
      }
    },
    drop(e) {
      if (e.dataTransfer.items) {
        // For now only process the first file
        if (e.dataTransfer.items[0].kind === 'file') {
          var file = e.dataTransfer.items[0].getAsFile();
          if (this.validateFileType(file)) {
            this.files = [file];
            this.$emit('input', this.files[0])
          } else {
            // invalid
            this.invalid = true;
            window.setTimeout(() => {
              this.invalid = false;
            }, 1000);
          }
        }
      }
      this.hover = false;
    },
    inputChanged() {
      this.files = this.$refs.selector.files;
      this.$emit('input', this.files[0]);
    },
    clear() {
      this.files = null;
      this.$emit('input');
    },
  }
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.file-select {
  display: block;
  //width: 100%;
}

.file-select-upload {
  display: none;
}

.file-select-inner, .clear {
  transition: border-color 0.2s;
}

.input-wrapper {
  width: 100%;
  display: flex;

  &.hover {
    .file-select-inner {
      border-color: #5b9bff;
    }

    .clear {
      border-top-color: #5b9bff;
      border-bottom-color: #5b9bff;
    }

    * {
      pointer-events: none;
    }
  }

  &.invalid {
    animation-name: shake;
    animation-duration: 1s;

    .file-select-inner {
      border-color: red;
    }

    .clear {
      border-top-color: red;
      border-bottom-color: red;
    }
  }
}

.file-select-inner {
  border: 1px solid #dcdfe6;
  border-right-width: 0;
  background: #fff;
  border-radius: 4px;
  flex: 1 0 auto;
  padding: 0 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 38px;
  display: flex;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.875em;
}


.name {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder, .size {
  color: #a9a9a9;
}

// Select button
.select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
}

// Clear button
.clear {
  border-radius: 0;
  border-right-width: 0;
}

.caption {
  font-size: 0.85714285714em;
  color: #999;
  line-height: 2;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
</style>
