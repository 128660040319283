<template>
  <div class="vendor-status-panel status-panel">
    <header>
      <h3>
        <span>{{ name }}</span>
        <el-tooltip v-if="edited" :content="$t('unsavedChanges')" effect="light">
          <i class="si-info-circle"></i>
        </el-tooltip>
      </h3>

      <el-button
        v-if="hasAnyPermission(['write:fandb', 'delete:fandb'])"
        @click="$emit('edit', id)"
        size="medium"
        type="primary"
      >
        {{ $t('editVendor') }}
      </el-button>
    </header>

    <el-form label-position="top" size="medium">
      <div class="inline-form-section">
        <div class="status">
          <el-form-item :label="$t('statusMessage')">
            <el-select
              v-if="messages && messages.length"
              :value="value.statusMessage"
              @input="set('statusMessage', $event)"
              clearable
            >
              <el-option
                v-for="message in messages"
                :key="message.id"
                :label="message.value"
                :value="message.value">
              </el-option>
            </el-select>

            <el-input
              v-else
              :value="value.statusMessage"
              @input="set('statusMessage', $event)"
            />
          </el-form-item>
        </div>

        <div class="switches">
          <el-form-item :label="$t('open')" class="center">
            <el-switch :value="value.operational" @input="set('operational', $event)"/>
          </el-form-item>

          <el-form-item :label="$t('customOpeningHours')" class="center">
            <el-switch :value="value.useCustomOpeningTimes" @input="set('useCustomOpeningTimes', $event)"/>
          </el-form-item>
        </div>

        <el-form-item :label="$t('openingHours')" class="times">
          <el-time-picker
            :disabled="!value.useCustomOpeningTimes"
            is-range
            v-model="openingTimes"
            range-separator="⁠–⁠"
            :start-placeholder="$t('openingTime')"
            :end-placeholder="$t('closingTime')"
            format="HH:mm"
          ></el-time-picker>
          </el-form-item>
        </div>
    </el-form>
  </div>
</template>

<script>
import { Input, Switch, Button, Form, FormItem, TimePicker, Tooltip, Select, Option } from 'element-ui'
import { mapGetters } from 'vuex';

export default {
  name: 'VendorStatusPanel',
  components: {
    'el-form': Form,
    'el-form-item': FormItem,
    'el-input': Input,
    'el-switch': Switch,
    'el-button': Button,
    'el-time-picker': TimePicker,
    'el-tooltip': Tooltip,
    'el-select': Select,
    'el-option': Option
  },
  computed: {
    openingTimes: {
      get() {
        var openingTime, closingTime;

        if (this.value && this.value.customOpeningTimes && this.value.useCustomOpeningTimes) {
          // Custom opening times have alread been set. Attempt to use them
          openingTime = this.toDateObject(this.value.customOpeningTimes.opens);
          closingTime = this.toDateObject(this.value.customOpeningTimes.closes);
        } else if (this.value.openingTime && this.value.closingTime) {
          // If we have exisiting opening times for this vendor use them
          openingTime = new Date(this.value.openingTime);
          closingTime = new Date(this.value.closingTime);
        } else {
          // Otherwise get the current time
          openingTime = new Date();
          closingTime = new Date();
        }

        return [
          openingTime,
          closingTime
        ];
      },
      set(newValue) {
        this.set('customOpeningTimes', {
          opens: this.toTimeString(newValue[0]),
          closes: this.toTimeString(newValue[1])
        });
      }
    },
    ...mapGetters([
      'hasAnyPermission'
    ])
  },
  methods: {
    set(key, value) {
      // When any value is updated create and emit a new object.
      return this.$emit('input', { ...this.value, [key]: value }, this.id);
    },
    toDateObject(time) {
      // Convert a time string (hh:mm) to a Date object
      var [hours, mins] = time.split(':');
      var date = new Date();
      date.setHours(hours);
      date.setMinutes(mins);
      return date;
    },
    toTimeString(date) {
      // Convert a Date object to a time string (hh:mm)
      var hours = date.getHours().toString();
      var mins = date.getMinutes().toString();
      if (hours.length === 1) hours = '0' + hours;
      if (mins.length === 1) mins = '0' + mins;
      return hours + ':' + mins;
    }
  },
  props: {
    id: String, // Vendor ID
    messages: Array,
    edited: Boolean, // Has the status been edited?
    name: String, // Vendor name
    value: Object // Vendor status object
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/vars';

.status-panel {
  background: white;
  box-shadow: @panel-shadow;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}

.status-panel /deep/ .el-form-item__label {
  font-size: 0.75rem; // 12px
  line-height: 1.5;
}

.status-panel /deep/ .center {
  text-align: center;
}

// Override date editor width
.status-panel /deep/ .el-date-editor {
  width: 250px;
}

.status-panel /deep/ input::-webkit-outer-spin-button,
.status-panel /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inline-form-section {
  display: grid;
  grid-template-columns: 25rem 1.5fr auto;
  grid-template-rows: 1fr;
  gap: 0.75em 0;
  grid-template-areas:
    "status switches times";

  .status {
    grid-area: status;
    display: flex;
  }

  .time {
    grid-area: time;
  }

  .switches {
    grid-area: switches;
    display: flex;
    justify-self: end
  }

  .times {
    grid-area: times;
  }
}

// Panel header section
header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.el-form-item {
  padding: 0 0.75em;
  margin-bottom: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

h3 {
  margin: 0;

  span, i {
    vertical-align: middle;
  }

  i {
    color: @orange;
  }
}

@media only screen and (max-width: 1250px) {
  .inline-form-section {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "status status"
      "switches times";

    .status {
      justify-self: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .inline-form-section {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "status"
      "switches"
      "times";

    .switches, .times {
      justify-self: center;
    }
  }
}
</style>